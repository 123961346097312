import { ApiResult, apiUrl } from '../../../api';
import { getAccessToken } from '../../../store/authEffect';
import {
  EMailValidationResult,
  ProjectNumberValidationResult,
} from '../../../models/ValidationResults';
import {
  AdditionalProjectInformation,
  AttributeValidations,
  CreateProjectDraftByProjectDto,
  CreateProjectDraftDto,
  CreateProjectDraftValidationDto,
  Project,
  ProjectDocumentSetting,
  ProjectNews,
  ProjectStatus,
  ProjectToCreatePreview,
  UpdateProjectDraftDto,
  ValidateProjectForCreationModel,
} from '../../../models/Project';
import {
  GlobalProjectSettingType,
  HourlyRateId,
  ProjectExtensionId,
  ProjectId,
} from '../../../models/Types';
import { Planner } from '../../../models/Planner';
import { ProjectSetting, ProjectMailSetting } from '../../../models/Project';
import { InternalProjectContact } from '../../../models/ProjectContacts';
import fetchWithRetry from '../../../util/fetchWithRetry';
import {
  CreateHourlyRateRequest,
  HourlyRate,
  HourlyRateSuggestion,
} from '../../../models/HourlyRate';
import {
  AvailableProjectExtension,
  CreateProjectExtension,
  ProjectExtension,
  ProjectExtensionAccess,
  TriggerTypes,
  UpdateProjectExtension,
  UpdateUserExtensionAccess,
} from '../../../models/ProjectExtension';
import { ProjectMembership } from '../../../models/Contact';

export const apiCreateProjectPreview: (
  validateProjectForCreation: ValidateProjectForCreationModel
) => Promise<ApiResult<ProjectToCreatePreview>> = async (
  validateProjectForCreation
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/createProjectPreview`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(validateProjectForCreation),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectToCreatePreview)
      : null;
  return {
    result,
    data,
  };
};

export const apiValidateProjectEmail: (
  email: string
) => Promise<ApiResult<EMailValidationResult>> = async (email) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/validateNewGroupEmail?email=${encodeURI(email)}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiValidateProjectNumber: (
  projectNumber: string
) => Promise<ApiResult<ProjectNumberValidationResult>> = async (
  projectNumber
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/validateProjectNumber?projectNumber=${encodeURI(
      projectNumber
    )}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiFetchProjectStatus: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectStatus>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/status`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectStatus)
      : null;
  return {
    result,
    data,
  };
};

export const apiFixProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/${projectId}/fix`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );
  return result;
};

export const apiDeleteHourlyRate: (
  projectId: ProjectId,
  hourlyRateId: HourlyRateId
) => Promise<ApiResult<void>> = async (projectId, hourlyRateId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate/${hourlyRateId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiCreateProjectFolder: (
  projectId: ProjectId,
  folderStructureId: string
) => Promise<Response> = async (projectId, folderStructureId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/createFolderStructure/${folderStructureId}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiPrecacheResync: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/emailsync/EmailSync/project/${projectId}/precacheResync`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiStartProjectSubscription: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/Project/${projectId}/startSubscription`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiFixProjectSubscription: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectFix/Project/${projectId}/fixSubscription`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiCreateProjectSharepoint: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/createSharepoint`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      method: 'POST',
    }
  );

  return result;
};

export const apiFetchPlanners: (
  projectId: ProjectId
) => Promise<ApiResult<Planner[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/planner`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchTeams: (projectId: ProjectId) => Promise<number> = async (
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Team/${projectId}/teams`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  return result.status;
};

export const apiCreateTeams: (projectId: ProjectId) => Promise<number> = async (
  projectId
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Team/${projectId}/createTeam`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({}),
    }
  );

  return result.status;
};

export const apiFinishProjectCreation: (
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/${projectId}/finish`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    data,
    result,
  };
};

export const apiCreateProjectDraft: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<Project>> = async (createProjectDraftDto) => {
  const result = await fetchWithRetry(`${apiUrl}/project/projectCreation`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(createProjectDraftDto),
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectDraft: (
  updateProjectDraftDto: UpdateProjectDraftDto,
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (updateProjectDraftDto, projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiGetProjectDraftsById: () => Promise<
  ApiResult<Project[]>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/project/projectCreation`, {
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiValidateProjectDraft: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<CreateProjectDraftValidationDto>> = async (
  createProjectDraftDto
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/validateProjectDraft`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as CreateProjectDraftValidationDto)
      : null;
  return {
    result,
    data,
  };
};
export const apiCreateProjectDraftByProject: (
  createProjectDraftByProjectDto: CreateProjectDraftByProjectDto
) => Promise<ApiResult<Project>> = async (createProjectDraftByProjectDto) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/${createProjectDraftByProjectDto.draftProjectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftByProjectDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiGetProjectById: (
  projectId: ProjectId
) => Promise<ApiResult<Project>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/project/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};
export const apiProjectExtensionTrigger: (
  projectId: ProjectId,
  projectExtensionId: string,
  triggerType: TriggerTypes
) => Promise<Response> = async (projectId, projectExtensionId, triggerType) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectExtension/${projectExtensionId}/trigger`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        triggerType: triggerType,
      }),
    }
  );

  return result;
};

export const apiValidateProjectExtensionForProject: (
  projectId: ProjectId,
  createProjectExtension: CreateProjectExtension
) => Promise<ApiResult<AttributeValidations>> = async (
  projectId,
  createProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/validate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as AttributeValidations)
      : null;
  return {
    result,
    data,
  };
};
export const apiValidateProjectCreation: (
  createProjectDraftDto: CreateProjectDraftDto
) => Promise<ApiResult<CreateProjectDraftValidationDto>> = async (
  createProjectDraftDto
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/projectCreation/validateProjectDraft`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectDraftDto),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchInternalProjectContacts: (
  projectId: ProjectId
) => Promise<ApiResult<InternalProjectContact[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/InternalProjectContact`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchAllProjectSettings: (
  projectId: ProjectId,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectSetting[]>> = async (projectId, signal) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectMailSetting: (
  projectId: ProjectId,
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectMailSetting>> = async (
  projectId,
  type,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectMailSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectMailSetting
) => Promise<ApiResult<ProjectMailSetting>> = async (
  type,
  projectId,
  setting
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectMailSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectMailSetting
) => Promise<ApiResult<ProjectMailSetting>> = async (
  type,
  projectId,
  setting
) => {
  setting.projectId = projectId;
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${setting.projectSettingId}/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectDocumentSetting: (
  projectId: ProjectId,
  type: GlobalProjectSettingType,
  signal?: AbortSignal
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  projectId,
  type,
  signal
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: { Authorization: `Bearer ${await getAccessToken()}` },
      signal,
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectDocumentSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectDocumentSetting
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  type,
  projectId,
  setting
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectDocumentSetting: (
  type: GlobalProjectSettingType,
  projectId: ProjectId,
  setting: ProjectDocumentSetting
) => Promise<ApiResult<ProjectDocumentSetting>> = async (
  type,
  projectId,
  setting
) => {
  setting.projectId = projectId;
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectSetting/${setting.projectSettingId}/${type}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(setting),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectNews: () => Promise<
  ApiResult<ProjectNews>
> = async () => {
  const result = await fetchWithRetry(`${apiUrl}/project/ProjectNews`, {
    headers: { Authorization: `Bearer ${await getAccessToken()}` },
  });
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectMeNotification: (
  projectId: ProjectId
) => Promise<ApiResult<boolean>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/internalProjectContact/notification/me`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectMeNotification: (
  projectId: ProjectId,
  isNotificationEnabled: boolean
) => Promise<ApiResult<InternalProjectContact>> = async (
  projectId,
  isNotificationEnabled
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/internalProjectContact/notification/me?isNotificationEnabled=${isNotificationEnabled}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetGlobalHourlyRateSuggestions: () => Promise<
  ApiResult<HourlyRateSuggestion[]>
> = async () => {
  const result = await fetchWithRetry(
    `${apiUrl}/setting/hourlyRateSuggestion`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiGetProjectHourlyRate: (
  projectId: ProjectId
) => Promise<ApiResult<HourlyRate[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate/currentHourlyRate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiPushHourlyRateForProject: (
  projectId: ProjectId,
  createHourlyRateRequests: CreateHourlyRateRequest[]
) => Promise<ApiResult<HourlyRate[]>> = async (
  projectId,
  createHourlyRateRequests
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/HourlyRate`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',

      body: JSON.stringify(createHourlyRateRequests),
    }
  );
  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;
  return {
    result,
    data,
  };
};

export const apiDeleteProjectCreationDraft: (
  projectId: ProjectId
) => Promise<ApiResult<void>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectCreation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiDeleteProjectExtensionForProject: (
  projectId: ProjectId,
  projectExtensionId: ProjectExtensionId
) => Promise<ApiResult<void>> = async (projectId, projectExtensionId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/${projectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }
  );
  return { result };
};

export const apiFetchProjectExtensionsForProject: (
  projectId: ProjectId
) => Promise<ApiResult<ProjectExtension[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiFetchAvailableProjectExtensionsForProject: (
  projectId: ProjectId
) => Promise<ApiResult<AvailableProjectExtension[]>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/available`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as AvailableProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiCreateProjectExtensionForProject: (
  projectId: ProjectId,
  createProjectExtension: CreateProjectExtension[]
) => Promise<ApiResult<ProjectExtension[]>> = async (
  projectId,
  createProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(createProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension[])
      : null;
  return {
    result,
    data,
  };
};

export const apiUpdateProjectExtensionForProject: (
  projectId: ProjectId,
  projectExtensionId: ProjectExtensionId,
  updateProjectExtension: UpdateProjectExtension
) => Promise<ApiResult<ProjectExtension>> = async (
  projectId,
  projectExtensionId,
  updateProjectExtension
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/ProjectExtension/${projectExtensionId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(updateProjectExtension),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtension)
      : null;

  return {
    result,

    data,
  };
};

export const apiUpdateProjectExtensionAccesses: (
  projectId: ProjectId,
  accesses: UpdateUserExtensionAccess[]
) => Promise<ApiResult<ProjectExtensionAccess[]>> = async (
  projectId,
  accesses
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/${projectId}/projectExtensionAccess`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(accesses),
    }
  );
  const data =
    result.status >= 200 && result.status < 300
      ? ((await result.json()) as ProjectExtensionAccess[])
      : null;
  return {
    result,
    data,
  };
};

export const apiFetchProjectMemberships: (
  contactId: string
) => Promise<ApiResult<ProjectMembership>> = async (contactId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/ProjectMember/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiArchiveProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/archive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectId),
    }
  );
  return result;
};

export const apiUnarchiveProject: (
  projectId: ProjectId
) => Promise<Response> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/Project/${projectId}/unarchive`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(projectId),
    }
  );
  return result;
};

export const apiGetAdditionalProjectInformation: (
  projectId: ProjectId
) => Promise<ApiResult<AdditionalProjectInformation>> = async (projectId) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/AdditionalInformation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'GET',
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};

export const apiUpdateAdditionalProjectInformation: (
  projectId: ProjectId,
  additionalProjectInformation: {
    newContent1: string;
    newContent2: string;
    newContent3: string;
    newContent4: string;
    newContent5: string;
  }
) => Promise<ApiResult<AdditionalProjectInformation>> = async (
  projectId,
  additionalProjectInformation
) => {
  const result = await fetchWithRetry(
    `${apiUrl}/project/AdditionalInformation/${projectId}`,
    {
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(additionalProjectInformation),
    }
  );

  const data =
    result.status >= 200 && result.status < 300 ? await result.json() : null;

  return {
    result,
    data,
  };
};
