import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Form } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import {
  DriveItem,
  ListItemFieldColumnName,
  UpdateDriveItemField,
} from '../../../../models/Drive';
import {
  DriveItemListItemId,
  GroupId,
  ProjectId,
} from '../../../../models/Types';
import DocumentTagPickerMultiSelect from '../DocumentTagPickerMultiSelect';
import CompanyPickerMultiSelect from '../../../companies/components/CompanyPickerMultiSelect';
import ContactPickerMultiSelect from '../../../contacts/components/ContactPickerMultiSelect';
import { useDispatch } from 'react-redux';
import { DEBOUNCED_DOCUMENT_META_DATA_UPDATE } from '../../sagas/watchUpdateDriveItemMetaData';
import Flex from '../../../../components/Flex';
import useDebounce from '../../../../hooks/useDebounce';
import { makePrioStyles } from '../../../../theme/utils';
import { debounceFunction } from '../../../../util';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  fullHeight: {
    height: '100%',
  },
  metaDataWrapper: {
    maxHeight: 'calc(100% - 32px)',
    overflowY: 'scroll',
  },
  maxPickerWidth: {
    width: '314px!important',
  },
  button: {
    marginTop: '16px',
  },
  lastFormItem: {
    marginBottom: '0px',
  },
}));

const debouncedCheckIfFormValuesChanged = debounceFunction(
  (checkIfFormValuesChanged: () => void) => {
    checkIfFormValuesChanged();
  },
  250
);

interface ById {
  [key: string]: string[];
}

interface ByIdOfById {
  [key: string]: ById;
}

interface SingleDriveItemFieldDto {
  listItemId: DriveItemListItemId;
  columnName: ListItemFieldColumnName;
  values: string[];
}

type DriveItemFieldDtos = Array<SingleDriveItemFieldDto>;

interface PreviewModalMetaDataFormMultiSelectProps {
  className?: string;
  groupId: GroupId;
  selectedDriveItems: DriveItem[];
  projectId?: ProjectId;
}

export const PreviewModalMetaDataFormMultiSelect: React.FC<
  PreviewModalMetaDataFormMultiSelectProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, groupId, selectedDriveItems, projectId } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const relevantMetaDataType: ListItemFieldColumnName[] = useMemo(
    () => ['Prio365CompanyIds', 'Prio365ContactIds', 'Prio365DriveItemTags'],
    []
  );
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const isFormFieldDisabled =
    !!!selectedDriveItems[0]?.sharepointIds?.listItemId;

  const selectedListItemIds: string[] = useMemo(
    () => selectedDriveItems.map((item) => item?.sharepointIds?.listItemId),
    [selectedDriveItems]
  );

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(true);
  const decouncedIsSaveButtonDisabled = useDebounce(isSaveButtonDisabled, 250);

  const relevantMetaData = useMemo(() => {
    return selectedDriveItems.reduce((acc, item) => {
      const presentMetaDataTypes = Object.keys(
        item.listItemFields
      ) as ListItemFieldColumnName[];
      const presentAndRelevantMetaDataTypes = presentMetaDataTypes.filter(
        (type) => relevantMetaDataType.includes(type)
      );

      const relevantMetaData = presentAndRelevantMetaDataTypes.reduce(
        (acc, type) => {
          return {
            ...acc,
            [type]: item.listItemFields[type],
          };
        },
        {}
      );
      if (
        presentMetaDataTypes.some((key) => relevantMetaDataType.includes(key))
      ) {
        return {
          ...acc,
          [item?.sharepointIds?.listItemId]: relevantMetaData,
        };
      }
      return acc;
    }, {});
  }, [selectedDriveItems, relevantMetaDataType]);

  const [companyInformation, setCompanyInformation] = useState<ById>({});
  const [contactInformation, setContactInformation] = useState<ById>({});
  const [tagInformation, setTagInformation] = useState<ById>({});

  const initialParsedMetaData: ByIdOfById = useMemo(() => {
    const getValues = (key: string) => {
      return selectedDriveItems.reduce((acc, item) => {
        if (item.listItemFields[key]) {
          const values: string[] = item.listItemFields[key];

          return {
            ...values.reduce((_acc, value) => {
              return {
                ..._acc,
                [value]: [
                  ...(_acc[value] || []),
                  item?.sharepointIds?.listItemId,
                ],
              };
            }, acc),
          };
        }
        return acc;
      }, {} as ById);
    };
    return {
      Prio365CompanyIds: getValues('Prio365CompanyIds'),
      Prio365ContactIds: getValues('Prio365ContactIds'),
      Prio365DriveItemTags: getValues('Prio365DriveItemTags'),
    };
  }, [selectedDriveItems]);

  const parsedMetaData: ByIdOfById = useMemo(() => {
    return {
      Prio365CompanyIds: companyInformation,
      Prio365ContactIds: contactInformation,
      Prio365DriveItemTags: tagInformation,
    };
  }, [tagInformation, companyInformation, contactInformation]);

  const [baseDriveItemFieldDtos, setBaseDriveItemFieldDtos] =
    useState<DriveItemFieldDtos>([]);

  const listItemIdsOfSelectedDriveItems = useMemo(
    () => selectedDriveItems.map((item) => item?.sharepointIds?.listItemId),
    [selectedDriveItems]
  );

  const initialDriveItemFieldDtos: DriveItemFieldDtos = useMemo(() => {
    let helperArray: DriveItemFieldDtos = [];
    selectedListItemIds.forEach((listItemId) => {
      relevantMetaDataType.forEach((columnName) => {
        const values = relevantMetaData[listItemId]?.[columnName];
        if (
          values?.length > 0 ||
          relevantMetaData[listItemId]?.[columnName]?.length > 0
        ) {
          helperArray.push({
            listItemId,
            columnName,
            values,
          });
        }
      });
    });
    return helperArray;
  }, [selectedListItemIds, relevantMetaDataType, relevantMetaData]);

  const originalDriveItemFieldDtos = useMemo(() => {
    return baseDriveItemFieldDtos.length > 0
      ? baseDriveItemFieldDtos
      : initialDriveItemFieldDtos;
  }, [initialDriveItemFieldDtos, baseDriveItemFieldDtos]);

  const updateDriveItemFieldDtos = useMemo(() => {
    const helperArray: DriveItemFieldDtos = [];

    selectedListItemIds.forEach((listItemId) => {
      relevantMetaDataType.forEach((columnName) => {
        const values = Object.entries(parsedMetaData[columnName]).reduce(
          (acc, [key, value]) => {
            if ((value as string[]).includes(listItemId)) {
              return [...acc, key];
            }
            return acc;
          },
          []
        );
        const finalValues =
          columnName === 'Prio365DriveItemTags'
            ? values.sort((a, b) => a.localeCompare(b))
            : values;
        if (
          values.length > 0 ||
          relevantMetaData[listItemId]?.[columnName]?.length > 0
        ) {
          helperArray.push({
            listItemId,
            columnName,
            values: finalValues,
          });
        }
      });
    });

    return helperArray;
  }, [
    parsedMetaData,
    selectedListItemIds,
    relevantMetaDataType,
    relevantMetaData,
  ]);

  //#region ------------------------------ Methods / Handlers
  const setsAreEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (const a of setA) if (!setB.has(a)) return false;
    return true;
  };

  const parseRelevantMetaDataToFormData = useCallback(() => {
    let _companyInformation: ById = {};
    let _contactInformation: ById = {};
    let _tagInformation: ById = {};

    const _relevantMetaData = Object.entries(relevantMetaData);

    _companyInformation = _relevantMetaData.reduce((acc, [key, value]) => {
      if (value['Prio365CompanyIds']) {
        if (value['Prio365CompanyIds'].length > 0) {
          let _companyInformation = acc;
          value['Prio365CompanyIds'].forEach((tagType) => {
            _companyInformation = {
              ..._companyInformation,
              [tagType]: [...(_companyInformation[tagType] || []), key],
            };
          });

          return {
            ..._companyInformation,
          };
        } else {
          return {
            ...acc,
            [value['Prio365CompanyIds'].toString()]: [
              ...(relevantMetaData[key]['Prio365CompanyIds'] || []),

              key,
            ],
          };
        }
      }
      return acc;
    }, {});
    setCompanyInformation(_companyInformation);

    _contactInformation = _relevantMetaData.reduce((acc, [key, value]) => {
      if (value['Prio365ContactIds']) {
        if (value['Prio365ContactIds'].length > 0) {
          let _contactInformation = acc;
          value['Prio365ContactIds'].forEach((tagType) => {
            _contactInformation = {
              ..._contactInformation,
              [tagType]: [...(_contactInformation[tagType] || []), key],
            };
          });

          return {
            ..._contactInformation,
          };
        } else {
          return {
            ...acc,
            [value['Prio365ContactIds'].toString()]: [
              ...(relevantMetaData[key]['Prio365ContactIds'] || []),
              key,
            ],
          };
        }
      }
      return acc;
    }, {});
    setContactInformation(_contactInformation);

    _tagInformation = _relevantMetaData.reduce((acc, [key, value]) => {
      if (value['Prio365DriveItemTags']) {
        if (value['Prio365DriveItemTags'].length > 0) {
          let _tagInformation = acc;
          value['Prio365DriveItemTags'].forEach((tagType) => {
            _tagInformation = {
              ..._tagInformation,
              [tagType]: [...(_tagInformation[tagType] || []), key],
            };
          });

          return {
            ..._tagInformation,
          };
        } else {
          return {
            ...acc,
            [value['Prio365DriveItemTags'].toString()]: [
              ...(relevantMetaData[key]['Prio365DriveItemTags'] || []),
              key,
            ],
          };
        }
      }
      return acc;
    }, {});
    setTagInformation(_tagInformation);
  }, [relevantMetaData]);

  const reduceUpdateDriveItemFieldDtos = useCallback(() => {
    setBaseDriveItemFieldDtos(updateDriveItemFieldDtos);
    const _reducedUpdateDriveItemFieldDtos = updateDriveItemFieldDtos.filter(
      (dto1) =>
        !originalDriveItemFieldDtos.some((dto2) => {
          const set1 = new Set(
            dto1.values.map((obj) => JSON.stringify(obj, (key, value) => value))
          );
          const set2 = new Set(
            dto2.values.map((obj) => JSON.stringify(obj, (key, value) => value))
          );

          return (
            dto1.listItemId === dto2.listItemId &&
            dto1.columnName === dto2.columnName &&
            setsAreEqual(set1, set2)
          );
        })
    );

    return _reducedUpdateDriveItemFieldDtos;
  }, [updateDriveItemFieldDtos, originalDriveItemFieldDtos]);

  const updateMetaData = () => {
    const updateDriveItemFieldDtos = reduceUpdateDriveItemFieldDtos();
    const requestBody: UpdateDriveItemField = {
      groupId,
      updateDriveItemFieldDtos,
    };
    const isParentRoot =
      selectedDriveItems[0].parentReference.path === '/drive/root:';

    if (groupId && updateDriveItemFieldDtos.length > 0) {
      dispatch({
        type: DEBOUNCED_DOCUMENT_META_DATA_UPDATE,
        payload: {
          requestBody,
          driveItemId: selectedDriveItems[0]?.parentReference.id,
          isRoot: isParentRoot,
        },
      });
    }
    setIsSaveButtonDisabled(true);
  };

  const checkIfFormValuesChanged = useCallback(() => {
    const result = Object.keys(initialParsedMetaData).reduce(
      (acc, fieldKey) => {
        if (acc) {
          const initialParsedMetaDataValuesLength = Object.keys(
            initialParsedMetaData[fieldKey]
          ).reduce((acc, key) => {
            return acc + initialParsedMetaData[fieldKey][key].length;
          }, 0);

          const parsedMetaDataValuesLength = Object.keys(
            parsedMetaData[fieldKey]
          ).reduce((acc, key) => {
            return acc + parsedMetaData[fieldKey][key].length;
          }, 0);
          return (
            Object.keys(initialParsedMetaData[fieldKey]).length ===
              Object.keys(parsedMetaData[fieldKey]).length &&
            initialParsedMetaDataValuesLength === parsedMetaDataValuesLength
          );
        }
        return acc;
      },
      true
    );
    setIsSaveButtonDisabled(result);
  }, [parsedMetaData, initialParsedMetaData]);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    parseRelevantMetaDataToFormData();
  }, [relevantMetaData, parseRelevantMetaDataToFormData]);

  useEffect(() => {
    debouncedCheckIfFormValuesChanged(checkIfFormValuesChanged);
  }, [checkIfFormValuesChanged]);
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <div className={classes.fullHeight}>
        <Form
          layout="vertical"
          form={form}
          onFinish={updateMetaData}
          className={classes.fullHeight}
        >
          <Flex.Column className={classes.fullHeight}>
            <Flex.Item className={classes.metaDataWrapper} flex={1}>
              <Form.Item
                label={t('documents:documentMetaData.form.tags')}
                name="Prio365DriveItemTags"
              >
                <DocumentTagPickerMultiSelect
                  parsedValues={tagInformation}
                  setParsedValues={setTagInformation}
                  listItemIdsOfSelectDriveItems={
                    listItemIdsOfSelectedDriveItems
                  }
                  className={classes.maxPickerWidth}
                  projectId={projectId}
                  disabled={isFormFieldDisabled}
                />
              </Form.Item>
              <Form.Item
                label={t('documents:documentMetaData.form.companies')}
                name="Prio365CompanyIds"
              >
                <CompanyPickerMultiSelect
                  parsedValues={companyInformation}
                  setParsedValues={setCompanyInformation}
                  listItemIdsOfSelectDriveItems={
                    listItemIdsOfSelectedDriveItems
                  }
                  placeholderLabel={t(
                    'documents:documentMetaData.companyPickerPlaceholder'
                  )}
                  className={classes.maxPickerWidth}
                  disabled={isFormFieldDisabled}
                />
              </Form.Item>
              <Form.Item
                label={t('documents:documentMetaData.form.contacts')}
                name="Prio365ContactIds"
                className={classes.lastFormItem}
              >
                <ContactPickerMultiSelect
                  parsedValues={contactInformation}
                  setParsedValues={setContactInformation}
                  listItemIdsOfSelectDriveItems={
                    listItemIdsOfSelectedDriveItems
                  }
                  placeholderLabel={t(
                    'documents:documentMetaData.contactPickerPlaceholder'
                  )}
                  className={classes.maxPickerWidth}
                  disabled={isFormFieldDisabled}
                />
              </Form.Item>
            </Flex.Item>
            <Flex.Row justifyContent="end">
              <Flex.Item>
                <Button
                  htmlType="submit"
                  disabled={decouncedIsSaveButtonDisabled}
                  className={classes.button}
                >
                  {t('documents:documentMetaData.form.save')}
                </Button>
              </Flex.Item>
            </Flex.Row>
          </Flex.Column>
        </Form>
      </div>
    </div>
  );
};

export default PreviewModalMetaDataFormMultiSelect;
