import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { ButtonProps } from '@prio365/prio365-react-library/lib/Button';
import {
  DriveItem,
  DriveItemDropTarget,
  FolderDriveItem,
} from '../../../models/Drive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DndDriveItemDropResult,
  DndDriveItemDto,
  DND_TYPE_DRIVE_ITEM_FILE,
} from '../../../dnd/types';
import {
  checkIfLink,
  checkIfOneNote,
  getPDFType,
  iconForFile,
  isDriveItemFolder,
} from '../util';
import { makePrioStyles } from '../../../theme/utils';
import {
  formatHumanFileSize,
  DefaultDateTimeFormatString,
} from '../../../util';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCompaniesByIdState,
  getContactsByIdState,
  getCurrentFolderChildren,
  getCurrentFolderItem,
  getCurrentFolderNextLink,
  getDocumentSettings,
  getDocumentsTableColumnWidths,
  getDriveFavorites,
  getDriveItemIsFetching,
  getErrorOfDriveItem,
  getFavorisingProgresses,
  getRemoteItemsByProjectId,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import {
  CompanyId,
  ContactId,
  DriveItemId,
  GroupId,
} from '../../../models/Types';
import { DriveUserRemoteItem } from '../../../models/Document';
import { Project } from '../../../models/Project';
import { useNavigate } from 'react-router-dom';
import {
  copyItemsIntoFolder,
  driveItemDeleted,
  fetchDriveItemsSagaAction,
} from '../actions';
import { setDocumentsMetaState } from '../actions/meta';
import {
  apiDeleteDriveItem,
  apiDownloadDriveItem,
  apiOpenDriveItemUrlFile,
  apiUnzipArchive,
} from '../api';
import { DropTargetMonitor } from 'react-dnd';
import classNames from 'classnames';
import Flex from '../../../components/Flex';
import moment from 'moment';
import DocumentsTableContextMenu from './DocumentsTableContextMenu';
import { useKeyboardModifiers } from '@prio365/prio365-react-library';
import {
  DriveFavorite,
  FavorisingProgress,
} from '../../../models/DriveFavorite';
import { useDriveItemActions } from '../hooks/useDriveItemActions';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import DocumentsTableRowIcon, {
  useDocumentsTableRowIconStyles,
} from './DocumentsTableRowIcon';
import { VirtualTable } from '@prio365/prio365-react-library';
import {
  CellProps,
  Column,
} from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import {
  VirtualTableBodyRef,
  VirtualTableLoadingProps,
} from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTableBody';
import { ColumnWidthsState } from '@prio365/prio365-react-library/lib/VirtualTable/components/InnerVirtualTable';
import { updateDocumentsTableWidths } from '../actions/columnWidths';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { IndexRange } from 'react-virtualized';
import { apiFetchSavedAttachmentMetadata } from '../../mail/api';

const MENU_BUTTON_WIDTH = 40;

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    width: '100%',
    height: '100%',
    marginTop: theme.old.spacing.unit(2),
    border: '1px solid #e8e8e8',
    borderRadius: theme.borders.radius,
    '& .ant-btn-icon-only > *': {
      fontSize: theme.old.components.documentsTable.font.fontSize,
    },
  },
  rowMenuButtonIcon: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
  primaryColumn: {
    paddingLeft: 10,
    '& > span': {
      height: '100%',
      width: '100%',
      padding: `0 ${theme.old.spacing.baseSpacing}px`,
    },
  },
  documentsRow: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    alignItems: 'center',
    width: '100%',
  },
  documentsTextSpan: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    flex: 1,
  },
  menuButtons: {
    height: '100%',
    display: 'flex',
    marginLeft: 4,
    '& button': {
      backgroundColor: 'transparent',
      display: 'none',
    },
  },
  menuButton: {
    height: '100%',
    width: MENU_BUTTON_WIDTH,
    zIndex: 1,
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  simplifiedMenuButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: MENU_BUTTON_WIDTH,
    color: theme.old.palette.chromaticPalette.grey,
  },
  noItemsScreen: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  noItemsIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '200px',
    height: '200px',
    position: 'absolute',
    left: 'calc(50% - 100px)',
    top: 'calc(50% - 100px)',
  },
  noItemsText: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.45)',
  },
  fileColumn: {
    width: 40,
  },
  tableRow: {
    '&:hover $menuButtons': {
      '& $simplifiedMenuButton': {
        display: 'none',
      },
      '& $menuButton': {
        display: 'flex',
      },
    },
  },
  folderRow: {
    cursor: 'pointer',
  },
  vTable: {
    fontSize: theme.old.components.documentsTable.font.fontSize,
  },
  vTableHeaderColumn: {
    fontSize: theme.old.components.documentsTable.font.fontSize - 2,
  },
  maskedIcon: {
    '& mask g g': {
      transform: 'translate(11em, 9em) scale(.75)',
    },
  },
}));

interface DocumentsTableProps {
  id: string;
  className?: string;
  groupId: GroupId;
  activeProject: Project;
  pathPrefix?: string;
  mode: 'full' | 'widgetbar';
  searchOnline?: () => { searchTerm: string };
  selectedDriveItemIds?: string[];
  onDriveItemClick?: (driveItem: DriveItem, mimeType: string) => void;
  onDriveItemDoubleClick?: (
    driveItem: DriveItem,
    mimeType: string,
    e: React.MouseEvent
  ) => void;

  onMenuRename?: (driveItem: DriveItem) => void;
  onMenuDelete?: (driveItem: DriveItem) => void;
  onMenuDownload?: (driveItem: DriveItem) => void;
  onMenuCopy?: (driveItem: DriveItem) => void;
  onMenuMove?: (driveItem: DriveItem) => void;
  onDndMove?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>
    // targetItem: FolderDriveItem
  ) => void;
  onDndCopy?: (
    item: unknown,
    monitor: DropTargetMonitor<unknown, unknown>,
    targetItem: FolderDriveItem
  ) => void;
  onCopyAsPdf?: (driveItem: DriveItem) => void;
  onCreateRemoteItem: (driveItem: DriveItem) => void;
  onDeleteRemoteItem: (remoteDriveItem: DriveUserRemoteItem) => void;
  onCreateDriveFavorite: (driveItem: DriveItem) => void;
  onDeleteDriveFavorite: (driveFavorite: DriveFavorite) => void;
  onRefresh?: VoidFunction;
  onChange?: (driveItems: DriveItem[]) => void;
  onMoveItemsIntoProject?: (
    driveItems: DriveItem[],
    destinationProject: Project,
    dropEffect: 'copy' | 'move'
  ) => void;
  onMoveItemsIntoRemote?: VoidFunction;
  driveItemsFromSearch?: DriveItem[];
  searchIsFetching?: boolean;
  setIsSearchResultFresh?: (value: boolean) => void;
  isKeywordSearch?: boolean;
  setIsFocused?: (value: boolean) => void;
  onOpenParentFolder?: (driveItem: DriveItem) => void;
  selectedDriveItemId: DriveItemId;
  projectId: DriveItemId;
}

export const DocumentsTable: React.FC<DocumentsTableProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    id: tableId,
    groupId,
    activeProject,
    pathPrefix,
    selectedDriveItemIds: preSelectedDriveItemIds,
    searchOnline,
    onDriveItemClick: handleDriveItemClick,
    onDriveItemDoubleClick: handleDriveItemDoubleClickFromParent,
    onCreateRemoteItem: handleCreateRemoteItemFromParent,
    onDeleteRemoteItem: handleDeleteRemoteItemFromParent,
    onMenuRename: handleMenuRenameFromParent,
    onMenuDelete: handleMenuDeleteFromParent,
    onMenuMove: handleMenuMoveFromParent,
    onMenuDownload: handleMenuDownloadFromParent,
    onMenuCopy: handleMenuCopyFromParent,
    onCreateDriveFavorite: handleCreateDriveFavoriteFromParent,
    onDeleteDriveFavorite: handleDeleteDriveFavoriteFromParent,
    onDndMove,
    onChange: handleChangeDriveItems,
    onMoveItemsIntoProject,
    onCopyAsPdf,
    driveItemsFromSearch,
    searchIsFetching,
    isKeywordSearch,
    setIsFocused,
    onOpenParentFolder,
    selectedDriveItemId,
    projectId,
  } = props;

  const classes = useStyles(props);
  const documentsTableRowIconClasses = useDocumentsTableRowIconStyles();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isRoot = !selectedDriveItemId;

  const { altIsPressed } = useKeyboardModifiers();

  const { openInBrowser, openLocally } = useDriveItemActions(
    groupId,
    projectId
  );

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const ref = useRef<VirtualTableBodyRef>(null);

  const [clicked, setClicked] = useState(false);

  const selectedDriveItemFolderId =
    selectedDriveItemId ?? `root-group-${groupId}`;

  const rootFolderDriveItem = useSelector<RootReducerState, DriveItem>(
    (state) => getCurrentFolderItem(state, selectedDriveItemFolderId)
  );

  const driveItems = useSelector<RootReducerState, DriveItem[]>((state) =>
    getCurrentFolderChildren(state, selectedDriveItemFolderId)
  );

  const driveFavorites = useSelector<RootReducerState, DriveFavorite[]>(
    (state) => getDriveFavorites(state)
  );

  const driveItemFavorisingProgresses = useSelector<
    RootReducerState,
    FavorisingProgress[]
  >(getFavorisingProgresses);

  const [selectedDriveItems, setSelectedDriveItems] = useState<DriveItem[]>([]);

  const remoteDriveItems = useSelector<RootReducerState, DriveUserRemoteItem[]>(
    (state) => getRemoteItemsByProjectId(state, projectId)
  );

  const driveItemsNextLink = useSelector<RootReducerState, string>((state) =>
    getCurrentFolderNextLink(state, selectedDriveItemFolderId)
  );

  const documentsSettings = useSelector(getDocumentSettings);

  const contactsByIdState = useSelector(getContactsByIdState);

  const companiesByIdState = useSelector(getCompaniesByIdState);

  const columnWidths = useSelector(getDocumentsTableColumnWidths);

  const errorMessage = useSelector<RootReducerState, string>((state) =>
    getErrorOfDriveItem(state, selectedDriveItemFolderId)
  );

  const [isRowButtonClicked, setIsRowButtonClicked] = useState(false);

  //#region -------------------------------- States

  const { isFetching, isFetchingWithNextLink } = useSelector<
    RootReducerState,
    { isFetching: boolean; isFetchingWithNextLink: boolean }
  >((state) =>
    getDriveItemIsFetching(
      state,
      isRoot ? `root-group-${groupId}` : selectedDriveItemFolderId
    )
  );

  const getDriveItems = useCallback(() => {
    if (driveItemsFromSearch) {
      return driveItemsFromSearch;
    }
    return driveItems;
  }, [driveItems, driveItemsFromSearch]);

  //#endregion

  //#endregion

  //#region -------------------------------- Methods
  //#endregion

  //#region ------------------------------  Handlers

  const onDriveItemClick = useCallback(
    (driveItem: DriveItem) => {
      setIsFocused(false);
      const isFolder = isDriveItemFolder(driveItem);
      if (isFolder) {
        navigate(`${pathPrefix}folder/${driveItem.id}`);
      }
    },
    [pathPrefix, navigate, setIsFocused]
  );

  const onDriveItemDoubleClick = useCallback(
    (driveItem: DriveItem, e: React.MouseEvent) => {
      if (checkIfLink(driveItem?.name)) {
        apiOpenDriveItemUrlFile(groupId, driveItem);
        return;
      }
      const isPdf = getPDFType(driveItem) === 'pdf';
      if (
        documentsSettings &&
        documentsSettings.doubleClickOpenBehaviour === 'local'
      ) {
        openLocally(driveItem, isPdf);
      }
      if (
        documentsSettings &&
        documentsSettings.doubleClickOpenBehaviour === 'browser'
      ) {
        openInBrowser(driveItem);
      }
    },
    [groupId, documentsSettings, openLocally, openInBrowser]
  );
  const onDrop = (target: FolderDriveItem) => {
    return {
      type: 'driveItem',
      object: target,
    } as DriveItemDropTarget;
  };

  const onDragEnd = useCallback(
    (item, monitor) => {
      if (monitor.didDrop()) {
        const dndDriveItemDto = monitor.getItem() as DndDriveItemDto;
        const dropResult = monitor.getDropResult() as DndDriveItemDropResult;

        if (dropResult.type === 'project') {
          const destinationProjectDropResult = dropResult.object as Project;
          onMoveItemsIntoProject(
            dndDriveItemDto.driveItems,
            destinationProjectDropResult,
            dropResult.dropEffect
          );
        }
        if (dropResult.type === 'driveItem') {
          const driveItemFolderDropResult =
            dropResult.object as FolderDriveItem;
          const isDestinationRoot =
            !driveItemFolderDropResult.driveItem.parentReference.path;

          const isDraggedItemSelected = dndDriveItemDto.driveItems.some(
            (x) => x.id === dndDriveItemDto.driveItem.id
          );
          const itemsForCopyOrMove = () => {
            if (!isDraggedItemSelected)
              return [
                {
                  name: dndDriveItemDto.driveItem.name,
                  driveItemId: dndDriveItemDto.driveItem.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                },
              ];
            return dndDriveItemDto.driveItems
              ? dndDriveItemDto.driveItems.map((item) => ({
                  name: item.name,
                  driveItemId: item.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                }))
              : [];
          };

          const originalItemsForCopyOrMove = () => {
            if (!isDraggedItemSelected) return [dndDriveItemDto.driveItem];
            return dndDriveItemDto.driveItems ? dndDriveItemDto.driveItems : [];
          };

          if (
            !(
              itemsForCopyOrMove()?.[0]?.deleteSourceDriveItem &&
              (dndDriveItemDto.sourceFolderId ===
                driveItemFolderDropResult.driveItem.id ||
                (isDestinationRoot &&
                  dndDriveItemDto.root.sourceIsRoot &&
                  groupId === driveItemFolderDropResult.groupId))
            )
          ) {
            dispatch(
              copyItemsIntoFolder(
                dndDriveItemDto.projectId,
                groupId,
                driveItemFolderDropResult.groupId,
                itemsForCopyOrMove(),
                originalItemsForCopyOrMove(),
                dndDriveItemDto.sourceFolderId,
                driveItemFolderDropResult.driveItem.id,
                dndDriveItemDto.root.sourceIsRoot,
                isDestinationRoot
              )
            );
          }

          onDndMove(item, monitor);
          handleChangeDriveItems && handleChangeDriveItems([]);
        }

        if (dropResult.type === 'remoteDriveItem') {
          const remoteDriveItemDropResult =
            dropResult.object as DriveUserRemoteItem;
          const isDestinationRoot = remoteDriveItemDropResult.isRootFolder;

          const isDraggedItemSelected = dndDriveItemDto.driveItems.some(
            (x) => x.id === dndDriveItemDto.driveItem.id
          );
          const itemsForCopyOrMove = () => {
            if (!isDraggedItemSelected)
              return [
                {
                  name: dndDriveItemDto.driveItem.name,
                  driveItemId: dndDriveItemDto.driveItem.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                },
              ];
            return dndDriveItemDto.driveItems
              ? dndDriveItemDto.driveItems.map((item) => ({
                  name: item.name,
                  driveItemId: item.id,
                  deleteSourceDriveItem: altIsPressed ? false : true,
                }))
              : [];
          };

          const originalItemsForCopyOrMove = () => {
            if (!isDraggedItemSelected) return [dndDriveItemDto.driveItem];
            return dndDriveItemDto.driveItems ? dndDriveItemDto.driveItems : [];
          };

          if (
            !(
              itemsForCopyOrMove()?.[0]?.deleteSourceDriveItem &&
              (dndDriveItemDto.sourceFolderId ===
                remoteDriveItemDropResult.remoteItemId ||
                (isDestinationRoot &&
                  dndDriveItemDto.root.sourceIsRoot &&
                  groupId === remoteDriveItemDropResult.groupId))
            )
          ) {
            dispatch(
              copyItemsIntoFolder(
                projectId,
                groupId,
                remoteDriveItemDropResult.groupId,
                itemsForCopyOrMove(),
                originalItemsForCopyOrMove(),
                dndDriveItemDto.sourceFolderId,
                remoteDriveItemDropResult.remoteItemId,
                dndDriveItemDto.root.sourceIsRoot,
                isDestinationRoot
              )
            );
          }

          onDndMove(item, monitor);
          handleChangeDriveItems && handleChangeDriveItems([]);
        }
      }
    },
    [
      projectId,
      groupId,
      altIsPressed,
      dispatch,
      handleChangeDriveItems,
      onDndMove,
      onMoveItemsIntoProject,
    ]
  );

  const getLoading = (): VirtualTableLoadingProps => {
    if (isFetchingWithNextLink) {
      return {
        type: 'footer',
      };
    }

    if (isFetching) {
      return {
        type: 'noItems',
      };
    }

    if (searchIsFetching) {
      return {
        type: 'overlay',
      };
    }

    return null;
  };

  const handleLoadMoreRows: (params: IndexRange) => Promise<any> =
    useCallback(async () => {
      let _searchTerm = '';
      if (searchOnline) {
        const { searchTerm } = searchOnline();
        _searchTerm = searchTerm;
      }
      if (driveItemsNextLink && _searchTerm.trim() === '') {
        dispatch(
          fetchDriveItemsSagaAction(
            projectId,
            groupId,
            selectedDriveItemId,
            250,
            isRoot,
            true,
            true
          )
        );
      }
    }, [
      dispatch,
      searchOnline,
      driveItemsNextLink,
      groupId,
      isRoot,
      projectId,
      selectedDriveItemId,
    ]);

  const handleOnColumnWidthsChange = useCallback(
    (columnsWidthsById: ColumnWidthsState) => {
      dispatch(updateDocumentsTableWidths(columnsWidthsById));
    },
    [dispatch]
  );

  const onChange = (items: DriveItem[]) => {
    setSelectedDriveItems(items);
    handleChangeDriveItems && handleChangeDriveItems(items);
  };

  const onCreateRemoteItem = useCallback(
    (driveItem: DriveItem) => {
      handleCreateRemoteItemFromParent(driveItem);
    },
    [handleCreateRemoteItemFromParent]
  );

  const onDeleteRemoteItem = useCallback(
    (remoteDriveItem: DriveUserRemoteItem) => {
      handleDeleteRemoteItemFromParent(remoteDriveItem);
    },
    [handleDeleteRemoteItemFromParent]
  );
  const onCreateDriveFavorite = useCallback(
    (driveItem: DriveItem) => {
      handleCreateDriveFavoriteFromParent(driveItem);
    },
    [handleCreateDriveFavoriteFromParent]
  );

  const onDeleteDriveFavorite = useCallback(
    (driveFavorite: DriveFavorite) => {
      handleDeleteDriveFavoriteFromParent(driveFavorite);
    },
    [handleDeleteDriveFavoriteFromParent]
  );

  const onOpenMessage = useCallback(
    async (driveItem: DriveItem) => {
      const messageIds =
        driveItem.listItemFields?.Prio365AttachedMessageImmutableId;

      const { data } = await apiFetchSavedAttachmentMetadata(messageIds);

      if (data && data.length > 0) {
        const { projectId, messageImmutableId } = data[0];

        const width = window.screen.availWidth / 2;
        const height = window.screen.availHeight / 2;
        window.open(
          `/view/${
            projectId.length === 0 ? 'me' : projectId
          }/message/${messageImmutableId}/details`,
          '_blank',
          `width=${width},height=${height},noopener,noreferrer`
        );
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'mail:errorMessages.messages.savedAttachmentMetadataError'
          ),
        });
      }
    },
    [t]
  );

  const onMenuRename = useCallback(
    (driveItem: DriveItem) => {
      handleMenuRenameFromParent(driveItem);
    },
    [handleMenuRenameFromParent]
  );
  const onMenuDelete = useCallback(
    (driveItem: DriveItem) => {
      Modal.confirm({
        icon: null,
        title: t('documents:confirmation.delete.title'),
        content: (
          <Flex.Column>
            <Typography.Text>
              {t('documents:confirmation.delete.content')}
            </Typography.Text>
            <ul>
              <li>
                <Typography.Text>{driveItem.name}</Typography.Text>
              </li>
            </ul>
          </Flex.Column>
        ),
        okText: t('documents:confirmation.delete.okText'),
        cancelText: t('documents:confirmation.delete.cancelText'),
        onOk() {
          const promise = new Promise<void>(async (resolve) => {
            const { result } = await apiDeleteDriveItem(groupId, driveItem.id);
            if (result.status >= 200 && result.status < 300) {
              dispatch(
                driveItemDeleted(
                  driveItem.id,
                  selectedDriveItemFolderId,
                  groupId
                )
              );
              if (selectedDriveItems.some((x) => x.id === driveItem.id)) {
                const newSelection = selectedDriveItems.filter(
                  (item) => item.id !== driveItem.id
                );
                setSelectedDriveItems(newSelection);
                dispatch(
                  setDocumentsMetaState({
                    areMultipleSelected: newSelection.length > 0,
                  })
                );
              }
            } else {
              notification.open({
                message: t('common:error'),
                description: t('documents:errorMessages.deleteDocumentError'),
              });
            }

            resolve();
          });
          return promise;
        },
        onCancel() {},
      });
      handleMenuDeleteFromParent(driveItem);
    },
    [
      selectedDriveItemFolderId,
      groupId,
      selectedDriveItems,
      dispatch,
      t,
      handleMenuDeleteFromParent,
    ]
  );

  const onMenuMove = useCallback(
    (driveItem: DriveItem) => {
      dispatch(
        setDocumentsMetaState({
          copyDocumentsDrawerState: 'move',
        })
      );
      handleMenuMoveFromParent(driveItem);
    },
    [dispatch, handleMenuMoveFromParent]
  );
  const onMenuCopy = useCallback(
    (driveItem: DriveItem) => {
      dispatch(
        setDocumentsMetaState({
          copyDocumentsDrawerState: 'copy',
        })
      );
      handleMenuCopyFromParent(driveItem);
    },
    [dispatch, handleMenuCopyFromParent]
  );
  const onMenuDownload = useCallback(
    async (driveItem: DriveItem) => {
      if (!(await apiDownloadDriveItem(groupId, driveItem))) {
        notification.open({
          message: t('common:error'),
          description: t('documents:errorMessages.downloadFileError'),
        });
      }
      handleMenuDownloadFromParent(driveItem);
    },
    [groupId, handleMenuDownloadFromParent, t]
  );

  const onUnzipArchive = useCallback(
    async (driveItem: DriveItem) => {
      if (driveItem) {
        await apiUnzipArchive(groupId, driveItem, selectedDriveItemFolderId);
      }
    },
    [groupId, selectedDriveItemFolderId]
  );

  const handleOnRow: (
    item: DriveItem,
    index: number,
    selectedItems: DriveItem[]
  ) => VirtualListItemOnRowProps<DndDriveItemDto, DriveItemDropTarget> =
    useCallback(
      (item, index, selectedItems) => {
        const isOneNoteFile = checkIfOneNote(item);
        return {
          className: classNames(
            classes.tableRow,
            isDriveItemFolder(item) && !isOneNoteFile && classes.folderRow
          ),
          draggable: {
            dragType: DND_TYPE_DRIVE_ITEM_FILE,
            dragItem: {
              driveItem: item,
              driveItems:
                selectedItems.length === 0 ||
                !selectedItems.some(
                  (selectedItem) => selectedItem.id === item.id
                )
                  ? [item]
                  : selectedItems,
              groupId: groupId,
              sourceFolderId: rootFolderDriveItem?.id ?? '',
              projectId: activeProject.projectId,
              root: {
                sourceFolderRootId: selectedDriveItemFolderId,
                sourceIsRoot: isRoot,
              },
            } as DndDriveItemDto,
            onDragEnd: onDragEnd,
          },
          droppable: isDriveItemFolder(item)
            ? {
                accept: [DND_TYPE_DRIVE_ITEM_FILE],
                onDrop: (_item, monitor) =>
                  onDrop({
                    projectId,
                    groupId,
                    driveItem: item,
                  }),
                determinCanDrop: (_item) => {
                  if (_item?.driveItem?.id === item?.id) {
                    return false;
                  }
                  return true;
                },
              }
            : undefined,
          onClick: (event) => {
            event.preventDefault();
            const { ctrlKey, metaKey, shiftKey } = event;
            if (
              onDriveItemClick &&
              !(
                ctrlKey ||
                (/Mac/.test(navigator.userAgent) && metaKey) ||
                shiftKey
              )
            ) {
              if (item.id) {
                onDriveItemClick(item);
                if (handleDriveItemClick)
                  handleDriveItemClick(item, item?.file?.mimeType);
              }
            }
          },
          onDoubleClick: (e) => {
            if (!isRowButtonClicked) {
              handleDriveItemDoubleClickFromParent
                ? handleDriveItemDoubleClickFromParent(
                    item,
                    item?.file?.mimeType,
                    e
                  )
                : onDriveItemDoubleClick(item, e);
            }
          },
        };
      },
      [
        classes,
        projectId,
        groupId,
        rootFolderDriveItem,
        activeProject,
        isRoot,
        selectedDriveItemFolderId,
        onDragEnd,
        handleDriveItemDoubleClickFromParent,
        handleDriveItemClick,
        onDriveItemDoubleClick,
        onDriveItemClick,
        isRowButtonClicked,
      ]
    );
  //#endregion

  //#region -------------------------------- Components
  const menu = useCallback(
    (entry: DriveItem) => {
      const oneGigaByteInBytes = 1073741824;
      const isUnzippable = () => {
        const whenSearching = isKeywordSearch
          ? entry.parentReference.id === rootFolderDriveItem?.id
            ? false
            : true
          : !isRoot;
        return (
          entry?.file?.mimeType === 'application/zip' &&
          whenSearching &&
          entry.size / oneGigaByteInBytes <= 2
        );
      };

      return (
        <DocumentsTableContextMenu
          className={classes.menuButton}
          iconClassname={classes.rowMenuButtonIcon}
          driveItem={entry}
          parentIsRoot={entry?.parentReference?.path === '/drive/root:'}
          onMenuRename={onMenuRename}
          onMenuCopy={onMenuCopy}
          onMenuMove={onMenuMove}
          onCopyAsPdf={onCopyAsPdf}
          onMenuDelete={onMenuDelete}
          onMenuDownload={onMenuDownload}
          onUnzipArchive={isUnzippable() ? onUnzipArchive : undefined}
          openInBrowser={activeProject ? openInBrowser : undefined}
          openParentFolder={onOpenParentFolder}
          openLocally={activeProject ? openLocally : undefined}
          isKeywordSearch={isKeywordSearch}
        />
      );
    },
    [
      classes,
      onMenuRename,
      onMenuCopy,
      onMenuMove,
      onCopyAsPdf,
      onMenuDelete,
      onMenuDownload,
      onUnzipArchive,
      onOpenParentFolder,
      activeProject,
      openInBrowser,
      openLocally,
      isRoot,
      rootFolderDriveItem?.id,
      isKeywordSearch,
    ]
  );

  const renderMenuButton = useCallback(
    (
      activeIcon: boolean,
      simplified: boolean,
      buttonProps: ButtonProps,
      iconName?: IconName,
      icon?: React.ReactNode
    ) => {
      return (
        <>
          <div className={classes.simplifiedMenuButton}>
            {activeIcon &&
              (icon ?? (
                <FontAwesomeIcon
                  className={classes.rowMenuButtonIcon}
                  icon={[activeIcon ? 'fas' : 'fal', iconName]}
                />
              ))}
          </div>
          {!simplified && (
            <Button
              {...buttonProps}
              className={classes.menuButton}
              type="default"
            >
              {icon ?? (
                <FontAwesomeIcon
                  className={classes.rowMenuButtonIcon}
                  icon={[activeIcon ? 'fas' : 'fal', iconName]}
                />
              )}
            </Button>
          )}
        </>
      );
    },
    [classes]
  );

  const renderMenuButtons = useCallback(
    (cellProps: CellProps<DriveItem>, simplified: boolean) => {
      const isRemoteItem = remoteDriveItems.find(
        (x) => x.remoteItemId === cellProps?.originalData?.id
      );

      const favorite = driveFavorites.find(
        (x) => x?.driveItemId === cellProps?.originalData?.id
      );

      const isFavorite = favorite !== null && favorite !== undefined;

      const isMessageAttachment =
        !!cellProps?.originalData?.listItemFields
          ?.Prio365AttachedMessageId?.[0];
      const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!clicked) {
          setClicked(true);
          if (!isRemoteItem) {
            onCreateRemoteItem(cellProps.originalData);
          }
          if (isRemoteItem) {
            onDeleteRemoteItem(
              remoteDriveItems.find(
                (row) => row.remoteItemId === cellProps?.originalData?.id
              )
            );
          }
          setTimeout(() => {
            setClicked(false);
          }, 5000);
        }
      };

      return (
        <div className={classes.menuButtons}>
          {isDriveItemFolder(cellProps?.originalData) &&
            !(cellProps?.originalData?.package?.type === 'oneNote') &&
            renderMenuButton(
              isFavorite,
              simplified,
              {
                onClick: (e) => {
                  e.stopPropagation();
                  setIsRowButtonClicked(true);

                  if (!isRowButtonClicked) {
                    setTimeout(() => {
                      setIsRowButtonClicked(false);
                    }, 1000);
                    isFavorite
                      ? onDeleteDriveFavorite(favorite)
                      : onCreateDriveFavorite(cellProps?.originalData);
                  } else {
                    setIsRowButtonClicked(false);
                  }
                },
                // onDoubleClick: (e) => {
                //   e.stopPropagation();
                //   e.preventDefault();
                // },
                disabled:
                  isRowButtonClicked ??
                  driveItemFavorisingProgresses.some(
                    (x) => x.driveItemId === cellProps?.originalData?.id
                  ),
              },
              'star'
            )}

          {isDriveItemFolder(cellProps?.originalData) &&
            renderMenuButton(
              !!isRemoteItem,
              simplified,
              {
                onClick: handleClick,
                disabled: clicked,
                // onDoubleClick: (e) => {
                //   e.stopPropagation();
                //   e.preventDefault();
                // },
              },
              'cloud'
            )}
          {isMessageAttachment &&
            renderMenuButton(
              true,
              simplified,
              {
                onClick: (e) => {
                  e.stopPropagation();
                  onOpenMessage(cellProps.originalData);
                },
              },
              'envelope',
              <div className="fa-layers">
                <FontAwesomeIcon
                  icon={['fas', 'circle']}
                  mask={['fal', 'envelope']}
                  className={classes.maskedIcon}
                />
                <FontAwesomeIcon
                  icon={['fal', 'link']}
                  style={{
                    transform: 'scale(.75) translate(.5em, .25em)',
                  }}
                />
              </div>
            )}
          {(isRemoteItem || isFavorite || isMessageAttachment) && (
            <div className={classes.simplifiedMenuButton}></div>
          )}
          {!simplified && menu(cellProps.originalData)}
        </div>
      );
    },
    [
      classes,
      driveFavorites,
      remoteDriveItems,
      driveItemFavorisingProgresses,
      menu,
      onDeleteRemoteItem,
      onDeleteDriveFavorite,
      onCreateDriveFavorite,
      onCreateRemoteItem,
      onOpenMessage,
      renderMenuButton,
      isRowButtonClicked,
      clicked,
    ]
  );
  //#endregion

  //#region ------------------------------ Column Props
  const columns: Column<DriveItem>[] = useMemo(
    () =>
      [
        {
          id: 'file',
          alignSelf: true,
          width: columnWidths['file'] ?? 50,
          minWidth: 50,
          title: t('documents:table.columnTitle.isFolder'),
          accessor: 'file',
          sortingFn: (a, b) => {
            return iconForFile(a).localeCompare(iconForFile(b));
          },
          className: classes.fileColumn,
          Cell: (cellProps, driveItemList, { isScrolling, isVisible }) => (
            <DocumentsTableRowIcon
              driveItem={cellProps.originalData}
              groupId={groupId}
              projectId={projectId}
              driveItemList={driveItemList}
              simplyfied={isScrolling || !isVisible}
              classes={documentsTableRowIconClasses}
            />
          ),
        },
        {
          id: 'name',
          title: t('documents:table.columnTitle.name'),
          width: columnWidths['name'] ?? 200,
          innerStyle: {
            height: '100%',
          },
          minWidth: 4 * MENU_BUTTON_WIDTH,
          accessor: 'name',
          sortingFn: (rowA, rowB) => {
            return rowA.name.localeCompare(rowB.name);
          },
          Cell: (cellProps, driveItemList, { isScrolling, isVisible }) => {
            return (
              <div className={classes.documentsRow}>
                <div className={classes.documentsTextSpan}>
                  {cellProps.originalData.name}
                </div>
                {renderMenuButtons(cellProps, isScrolling || !isVisible)}
              </div>
            );
          },
          cellTitle: ({ name }) => name,
          className: classes.primaryColumn,
        },
        {
          id: 'lastModifiedDate',
          alignSelf: true,
          title: t('documents:table.columnTitle.lastModifiedDateTime'),
          accessor: 'lastModifiedDateTime',
          width: columnWidths['lastModifiedDate'] ?? 200,
          sortingFn: (a, b) => {
            return moment(a.lastModifiedDateTime).diff(
              moment(b.lastModifiedDateTime),
              'minutes'
            );
          },
          Cell: (cellProps) => DefaultDateTimeFormatString(cellProps.value),
          cellTitle: ({ lastModifiedDateTime }) =>
            DefaultDateTimeFormatString(lastModifiedDateTime),
        },
        {
          id: 'lastModifiedBy',
          alignSelf: true,
          title: t('documents:table.columnTitle.lastModifiedBy'),
          accessor: 'lastModifiedBy.user.displayName',
          width: columnWidths['lastModifiedBy'] ?? 200,
          Cell: (cellProps) => (
            <div className={classes.documentsRow}>
              <div className={classes.documentsTextSpan}>{cellProps.value}</div>
            </div>
          ),
          cellTitle: (driveItem) => driveItem.lastModifiedBy?.user?.displayName,
        },
        {
          id: 'fileSize',
          alignSelf: true,
          title: t('documents:table.columnTitle.size'),
          accessor: 'size',
          width: columnWidths['fileSize'] ?? 200,
          sortingFn: (a, b) => a.size - b.size,
          Cell: (cellProps) => formatHumanFileSize(cellProps.value),
          cellTitle: ({ size }) => formatHumanFileSize(size),
        },
        {
          id: 'alternativeName',
          alignSelf: true,
          title: t('documents:table.columnTitle.alternativeName'),
          accessor: 'listItemFields.Prio365AlternativeName',
          width: columnWidths['alternativeName'] ?? 200,
          sortingFn: (_a, _b) => {
            const a: string =
              _a.listItemFields?.Prio365AlternativeName?.[0] ?? '';
            const b: string =
              _b.listItemFields?.Prio365AlternativeName?.[0] ?? '';
            return a?.localeCompare(b);
          },
          Cell: (cellProps) => (
            <div className={classes.documentsRow}>
              <div className={classes.documentsTextSpan}>
                {cellProps.value?.[0]}
              </div>
            </div>
          ),
          cellTitle: (driveItem) =>
            driveItem?.listItemFields?.Prio365AlternativeName?.[0],
        },
        {
          id: 'documentTags',
          alignSelf: true,
          title: t('documents:table.columnTitle.driveItemTags'),
          accessor: 'listItemFields.Prio365DriveItemTags',
          width: columnWidths['driveItemTags'] ?? 200,
          Cell: (cellProps) => (
            <div className={classes.documentsRow}>
              <div className={classes.documentsTextSpan}>
                {(cellProps.value as string[])
                  ?.filter((name) => name.length > 0)
                  ?.join(', ')}
              </div>
            </div>
          ),
          cellTitle: (driveItem) =>
            (driveItem?.listItemFields?.Prio365DriveItemTags as string[])
              ?.filter((name) => name.length > 0)
              ?.join(', '),
        },
        {
          id: 'companies',
          alignSelf: true,
          title: t('documents:table.columnTitle.companies'),
          accessor: 'listItemFields.Prio365CompanyIds',
          width: columnWidths['companies'] ?? 200,
          Cell: (cellProps) => (
            <div className={classes.documentsRow}>
              <div className={classes.documentsTextSpan}>
                {(cellProps.value as CompanyId[])
                  ?.map(
                    (companyId) => companiesByIdState[companyId]?.fullName ?? ''
                  )
                  ?.filter((name) => name.length > 0)
                  ?.join(', ')}
              </div>
            </div>
          ),
          cellTitle: (driveItem) =>
            (driveItem?.listItemFields?.Prio365CompanyIds as CompanyId[])
              ?.map(
                (companyId) => companiesByIdState[companyId]?.fullName ?? ''
              )
              ?.filter((name) => name.length > 0)
              ?.join(', '),
        },
        {
          id: 'contacts',
          alignSelf: true,
          title: t('documents:table.columnTitle.contacts'),
          accessor: 'listItemFields.Prio365ContactIds',
          width: columnWidths['contacts'] ?? 300,
          Cell: (cellProps) => (
            <div className={classes.documentsRow}>
              <div className={classes.documentsTextSpan}>
                {(cellProps.value as ContactId[])
                  ?.map((contactId) => {
                    const contact = contactsByIdState[contactId];
                    return contact
                      ? `${contact.firstName ?? ''}${
                          contact.firstName && contact.lastName ? ' ' : ''
                        }${contact.lastName ?? ''}`
                      : '';
                  })
                  ?.filter((name) => name.length > 0)
                  ?.join(', ')}
              </div>
            </div>
          ),
          cellTitle: (driveItem) =>
            (driveItem?.listItemFields?.Prio365ContactIds as ContactId[])
              ?.map((contactId) => {
                const contact = contactsByIdState[contactId];
                return contact
                  ? `${contact.firstName ?? ''}${
                      contact.firstName && contact.lastName ? ' ' : ''
                    }${contact.lastName ?? ''}`
                  : '';
              })
              ?.filter((name) => name.length > 0)
              ?.join(', '),
        },
      ] as Column<DriveItem>[],
    [
      columnWidths,
      documentsTableRowIconClasses,
      classes,
      groupId,
      projectId,
      contactsByIdState,
      companiesByIdState,
      t,
      renderMenuButtons,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!!groupId) {
      dispatch(
        setDocumentsMetaState({
          areMultipleSelected: false,
          showDeleteDocumentsModal: false,
          copyDocumentsDrawerState: 'none',
          showCreateRemoteFolderModal: false,
        })
      );
    }
  }, [groupId, selectedDriveItemFolderId, dispatch, isRoot]);

  useEffect(() => {
    if (preSelectedDriveItemIds) {
      setSelectedDriveItems(
        getDriveItems()?.filter(
          (x) =>
            preSelectedDriveItemIds?.some(
              (preselectedId) => preselectedId === x.id
            )
        )
      );
    }
  }, [getDriveItems, preSelectedDriveItemIds]);

  useEffect(() => {
    if (ref.current) {
      ref.current.forceUpdate();
      ref.current.recomputeRowHeights();
    }
  }, [
    selectedDriveItemFolderId,
    documentsSettings,
    groupId,
    isRoot,
    driveFavorites,
    remoteDriveItems,
    driveItemFavorisingProgresses,
  ]);
  //#endregion

  return (
    <div
      className={classNames(classes.root, className)}
      id={'prio-project-documents-table'}
    >
      <VirtualTable<DriveItem>
        id={tableId}
        ref={ref}
        data={getDriveItems()}
        selectedItems={selectedDriveItems}
        columns={columns}
        resizable="absolute"
        rowHeight={theme.old.components.documentsTable.tableRowHeight}
        tableHeaderRowHeight={
          theme.old.components.documentsTable.tableColumnRowHeight
        }
        onCheckEquality={(a, b) => a.id === b.id}
        virtualTableBodyListType="infiniteArrowKeyList"
        loadMoreRows={handleLoadMoreRows}
        threshold={40}
        onRow={handleOnRow}
        onSelectionChange={onChange}
        noItemsScreen={
          errorMessage ? (
            <Flex.Column childrenGap={8} alignItems="center">
              <Flex.Item display="flex" width={40} height={40}>
                <FontAwesomeIcon
                  icon={['fad', 'folder-xmark']}
                  style={{
                    width: '100%',
                    height: '100%',
                    //@ts-ignore
                    '--fa-primary-color':
                      theme.old.palette.chromaticPalette.red,
                    '--fa-secondary-color':
                      theme.old.palette.chromaticPalette.yellow,
                    '--fa-primary-opacity': 1,
                    '--fa-secondary-opacity': 1,
                  }}
                />
              </Flex.Item>
              <Flex.Item>{errorMessage}</Flex.Item>
            </Flex.Column>
          ) : (
            <>{t('documents:table.noItems')}</>
          )
        }
        headerColumnClassname={classes.vTableHeaderColumn}
        className={classes.vTable}
        loading={getLoading()}
        onColumnWidthsChange={handleOnColumnWidthsChange}
        initialSortingFunction={(a, b) => {
          if (isDriveItemFolder(a) && !isDriveItemFolder(b)) {
            return -1;
          }
          if (!isDriveItemFolder(a) && isDriveItemFolder(b)) {
            return 1;
          }
          return a.name.localeCompare(b.name);
        }}
        rowsAreSelectable
      />
    </div>
  );
};

export default DocumentsTable;
