import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Col,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Row,
  Select,
  Typography,
} from 'antd';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { Button } from '@prio365/prio365-react-library';
import {
  OfficeHolidayFeedbackStatus,
  OfficeHolidayId,
  OfficeId,
} from '../../../models/Types';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import { rowGutter } from '../../../util/forms';
import moment from 'moment-timezone';
import {
  apiCopyOfficeHolidayToNextYear,
  apiCreateOfficeHoliday,
  apiDeleteHoliday,
  apiFetchOfficeHolidays,
  apiUpdateOfficeHoliday,
} from '../api';
import PrioSpinner from '../../../components/PrioSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { PrioTheme } from '../../../theme/types';
import { VirtualTable } from '@prio365/prio365-react-library';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import classNames from 'classnames';
import CustomSingleDatePicker from '../../../components/CustomSingleDatePicker';

const { Text } = Typography;

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
    '& .ant-spin-nested-loading': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-spin-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-container': {
      height: '100%',
      overflow: 'hidden',
    },
    '& .ant-table-cell > a': {
      color: theme.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  fullWidth: {
    width: '100%',
  },
  resetButton: {
    fontSize: 14,
    padding: 0,
  },
  trashIcon: {
    color: `${theme.old.palette.chromaticPalette.red}!important`,
  },
  lastColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    padding: theme.spacing.defaultPadding,
    flex: 1,
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
  },
  secondaryColumn: theme.old.components.table.secondaryColumn,
  collapse: {
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '&.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box':
      {
        padding: 0,
      },
  },
  divider: {
    '&.ant-divider-horizontal': {
      margin: 0,
      flex: 1,
      alignSelf: 'center',
      minWidth: 0,
    },
  },
  radioLabelCol: {
    width: 50,
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  flexRow: {
    flexDirection: 'row',
  },
  flexCol: {
    flexDirection: 'column',
  },
  smallErrorText: {
    fontSize: theme.typography.fontSize.small,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  showOverflow: {
    overflow: 'visible',
  },
}));

interface HRRestAbsencePageProps {
  className?: string;
  officeId?: OfficeId;
  newHolidayButtonClicked: boolean;
  setNewHolidayButtonClicked: (a) => void;
}

export const HRHolidayPage: React.FC<HRRestAbsencePageProps> = (props) => {
  //#region ------------------------------ Defaults
  const { officeId, newHolidayButtonClicked, setNewHolidayButtonClicked } =
    props;
  const classes = useStyles(props);
  const theme = useTheme() as PrioTheme;
  const { t } = useTranslation();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [year, setYear] = useState<number>(moment().year());
  const [onlyShowOfficeHolidays, setOnlyShowOfficeHolidays] =
    useState<boolean>(false);

  const [isHolidaysLoading, setIsHolidaysLoading] = useState<boolean>(false);
  const [holidayActionLoading, setHolidayActionLoading] = useState<
    OfficeHolidayId[]
  >([]);
  const [holidayActionFeedback, setHolidayActionFeedback] = useState<
    { id: OfficeHolidayId; status: OfficeHolidayFeedbackStatus }[]
  >([]);

  const [holidayModalVisible, setHolidayModalVisible] =
    useState<boolean>(false);
  const [holidayInModal, setHolidayInModal] = useState<OfficeHoliday>(null);

  const [deleteHolidayModalVisible, setDeleteHolidayModalVisible] =
    useState<boolean>(false);
  const [deleteHolidayIdInModal, setDeleteHolidayIdInModal] =
    useState<OfficeHolidayId>();

  const [holidaysList, setHolidaysList] = useState<OfficeHoliday[]>([]);

  const [filteredHolidaysList, setFilteredHolidaysList] = useState<
    OfficeHoliday[]
  >([]);

  //#region ------------------------------ Methods / Handlers

  const updateHolidayActionLoading = useCallback(
    (officeHolidayId: OfficeHolidayId, state: boolean) => {
      if (state) {
        if (!holidayActionLoading.includes(officeHolidayId))
          setHolidayActionLoading((holidayActionLoading) => [
            ...holidayActionLoading,
            officeHolidayId,
          ]);
      } else {
        setHolidayActionLoading((holidayActionLoading) =>
          holidayActionLoading.filter((h) => h !== officeHolidayId)
        );
      }
    },
    [holidayActionLoading]
  );

  const actionFeedback = useCallback(
    (
      officeHolidayId: OfficeHolidayId,
      status?: OfficeHolidayFeedbackStatus,
      timeout?: number
    ) => {
      if (!holidayActionFeedback.filter((h) => h.id === officeHolidayId)[0]) {
        setHolidayActionFeedback((holidayActionFeedback) => [
          ...holidayActionFeedback,
          { id: officeHolidayId, status },
        ]);
        setTimeout(() => {
          setHolidayActionFeedback((holidayActionFeedback) =>
            holidayActionFeedback.filter((h) => h.id !== officeHolidayId)
          );
        }, timeout || 1500);
      }
    },
    [holidayActionFeedback]
  );

  const fetchHolidays = useCallback(async () => {
    setIsHolidaysLoading(true);
    try {
      const { data } = await apiFetchOfficeHolidays(officeId);

      if (data) {
        setHolidaysList(data);
      } else {
        setHolidaysList([]);
        notification.open({
          message: t('common:error'),
          description: t('absences:errorMessages.fetchOfficeHolidaysError'),
        });
      }
    } catch (e) {}
    setIsHolidaysLoading(false);
  }, [t, officeId]);

  const handleYearPicker = (year: number) => {
    setYear(year);
  };

  const filterHolidays = useCallback(
    (holidaysList: OfficeHoliday[]) => {
      const yearString = year.toString();
      const list = holidaysList.filter((holiday, i) => {
        //#region ------------------------------ year filter
        const holidayYear = moment(holiday.date).format('YYYY');
        if (holidayYear !== yearString) return false;

        //#endregion

        //#region ------------------------------ onlyShowOfficeHolidays filter
        if (onlyShowOfficeHolidays && holiday.holidayType !== 'Office')
          return false;
        //#endregion

        return true;
      });
      return list;
    },
    [onlyShowOfficeHolidays, year]
  );

  const holidayWithDateAlreadyExists = useCallback(
    (date: string) => {
      const formatedDate = moment(date).format('YYYY-MM-DD');
      return holidaysList?.filter(
        (holiday) => moment(holiday?.date).format('YYYY-MM-DD') === formatedDate
      )?.[0]?.officeHolidayId
        ? true
        : false;
    },
    [holidaysList]
  );

  const validateHolidayInModal = useCallback(
    (holidayInModal: OfficeHoliday, noDateCheck?: boolean) => {
      if (!(holidayInModal?.name?.length > 0)) return false;
      if (!noDateCheck && holidayWithDateAlreadyExists(holidayInModal?.date))
        return false;

      return true;
    },
    [holidayWithDateAlreadyExists]
  );

  const handleHolidayModalOk = useCallback(
    async (holidayInModal?: OfficeHoliday) => {
      const isEditModal = !['new', 'copy'].includes(
        holidayInModal?.officeHolidayId
      );
      const valid = validateHolidayInModal(holidayInModal, isEditModal);
      if (!valid) return;
      updateHolidayActionLoading(holidayInModal?.officeHolidayId, true);
      try {
        if (!isEditModal) {
          const { data } = await apiCreateOfficeHoliday(
            {
              date: holidayInModal?.date,
              isoCode: holidayInModal?.isoCode || null,
              officeId,
              name: holidayInModal?.name,
              isHalfDay: holidayInModal?.isHalfDay,
            },
            officeId
          );
          if (!data)
            notification.open({
              message: t(
                'absences:officeHolidays.messages.error.holidayCreate'
              ),
            });
          else if (data?.officeHolidayId) {
            await fetchHolidays();
            actionFeedback(data?.officeHolidayId, 'new', 4000);
          }
        } else {
          if (holidayInModal?.name?.length > 0) {
            const { data } = await apiUpdateOfficeHoliday(
              {
                officeId,
                name: holidayInModal?.name,
                isHalfDay: holidayInModal?.isHalfDay,
              },
              holidayInModal?.officeHolidayId,
              officeId
            );
            if (!data) {
              actionFeedback(holidayInModal?.officeHolidayId, 'error');
              notification.open({
                message: t(
                  'absences:officeHolidays.messages.error.holidayUpdate'
                ),
              });
            } else {
              await fetchHolidays();
              actionFeedback(holidayInModal?.officeHolidayId, 'success');
            }
          }
        }
      } catch (e) {}
      updateHolidayActionLoading(holidayInModal?.officeHolidayId, false);

      setHolidayModalVisible(false);
    },
    [
      t,
      officeId,
      fetchHolidays,
      actionFeedback,
      updateHolidayActionLoading,
      validateHolidayInModal,
    ]
  );

  const handleDeleteHolidayModalOk = useCallback(
    async (holidayId?: OfficeHolidayId) => {
      updateHolidayActionLoading(holidayId, true);
      try {
        var { result } = await apiDeleteHoliday(holidayId, officeId);
        if (result?.type === 'error') {
          actionFeedback(holidayId, 'error', 3000);
          notification.open({
            message: t('absences:officeHolidays.messages.error.holidayDelete'),
          });
        } else {
          await fetchHolidays();
        }
      } catch (e) {}

      updateHolidayActionLoading(holidayId, false);
      setDeleteHolidayModalVisible(false);
    },
    [t, officeId, fetchHolidays, actionFeedback, updateHolidayActionLoading]
  );

  const onNew = useCallback(() => {
    setHolidayInModal({
      officeHolidayId: 'new',
      isoCode: '',
      date: moment().toISOString(),
      officeId,
      name: '',
      isHalfDay: false,
      holidayType: 'Office',
    });
    setHolidayModalVisible(true);
  }, [officeId]);

  const onCopy = useCallback(
    (holiday: OfficeHoliday) => {
      setHolidayInModal({
        officeHolidayId: 'copy',
        isoCode: holiday.isoCode,
        date: holiday.date,
        officeId,
        name: holiday.name,
        isHalfDay: holiday.isHalfDay,
        holidayType: 'Office',
      });
      setHolidayModalVisible(true);
    },
    [officeId]
  );

  const onEdit = useCallback((holiday: OfficeHoliday) => {
    setHolidayInModal({
      officeHolidayId: holiday.officeHolidayId,
      isoCode: holiday.isoCode,
      date: holiday.date,
      officeId: holiday.officeId,
      name: holiday.name,
      isHalfDay: holiday.isHalfDay,
      holidayType: 'Office',
    });
    setHolidayModalVisible(true);
  }, []);

  const onDelete = useCallback((holiday: OfficeHoliday) => {
    setDeleteHolidayIdInModal(holiday?.officeHolidayId);
    setDeleteHolidayModalVisible(true);
  }, []);

  const onCopyToNextYear = useCallback(
    async (holiday: OfficeHoliday) => {
      updateHolidayActionLoading(holiday?.officeHolidayId, true);

      const dateToTheFuture = moment(holiday?.date).add(1, 'years');

      if (holidayWithDateAlreadyExists(dateToTheFuture.toISOString())) {
        notification.open({
          message: t('absences:officeHolidays.messages.copyToNextYear.error'),
          description: t(
            'absences:officeHolidays.modal.errorText.datePicker.dateAlreadyExists'
          ),
        });
        actionFeedback(holiday?.officeHolidayId, 'error');
      } else {
        try {
          const { data } = await apiCopyOfficeHolidayToNextYear(
            holiday?.officeHolidayId,
            dateToTheFuture.format('YYYY'),
            officeId
          );

          if (data) {
            actionFeedback(holiday?.officeHolidayId, 'success');
          } else {
            actionFeedback(holiday?.officeHolidayId, 'error');
            notification.open({
              message: t(
                'absences:officeHolidays.messages.copyToNextYear.error'
              ),
            });
          }
        } catch (e) {}
      }

      updateHolidayActionLoading(holiday?.officeHolidayId, false);
      await fetchHolidays();
    },
    [
      fetchHolidays,
      holidayWithDateAlreadyExists,
      t,
      updateHolidayActionLoading,
      actionFeedback,
      officeId,
    ]
  );

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (newHolidayButtonClicked) {
      onNew();
      setNewHolidayButtonClicked(false);
    }
  }, [newHolidayButtonClicked, onNew, setNewHolidayButtonClicked]);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  useEffect(() => {
    const filtered = filterHolidays(holidaysList);
    setFilteredHolidaysList(filtered);
  }, [holidaysList, year, onlyShowOfficeHolidays, filterHolidays]);

  //#endregion

  //#region ------------------------------ Components
  const menuProjectList = useCallback(
    (
      officeHoliday: OfficeHoliday,
      allow: string[],
      onCopy: (officeHoliday: OfficeHoliday) => void,
      onEdit: (officeHoliday: OfficeHoliday) => void,
      onDelete: (officeHoliday: OfficeHoliday) => void,
      onCopyToNextYear: (officeHoliday: OfficeHoliday) => void
    ) => (
      <Menu>
        {(!allow || allow.includes('copy')) && (
          <Menu.Item
            key={'copyHolidays'}
            onClick={() => onCopy(officeHoliday)}
            icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.copy')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('copyToNextYear')) && (
          <Menu.Item
            key={'copyToNextYearHolidays'}
            onClick={() => onCopyToNextYear(officeHoliday)}
            icon={<FontAwesomeIcon icon={['fal', 'square-arrow-right']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.copyToNextYear')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('edit')) && (
          <Menu.Item
            key={'editHolidays'}
            onClick={() => onEdit(officeHoliday)}
            icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.edit')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('delete')) && (
          <Menu.Item
            key={'deleteHoliday'}
            onClick={() => onDelete(officeHoliday)}
            icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.delete')}
          </Menu.Item>
        )}
      </Menu>
    ),
    [t]
  );
  //endregion

  const getYearOptions = useCallback(() => {
    var options = [];
    var optionsJSX = [];

    for (let i = -1; i < 3; i++) {
      options.push(moment().year() - i);
    }

    const officeHolidays = holidaysList.filter(
      (h) => h.holidayType === 'Office'
    );

    const officeHolidaysYears = officeHolidays.map((h) =>
      moment(h.date).year()
    );

    options = uniq([...options, ...officeHolidaysYears]);

    options.sort((a, b) => b - a);

    for (let i = 0; i < options.length; i++) {
      optionsJSX.push(
        <Select.Option value={options[i]}>{options[i]}</Select.Option>
      );
    }

    return optionsJSX;

    function uniq(a: any[]) {
      var seen = {};
      return a.filter(function (item: any) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
      });
    }
  }, [holidaysList]);

  const getFeedbackIconForStatus = useCallback(
    (status: OfficeHolidayFeedbackStatus) => {
      const statuses = {
        success: (
          <FontAwesomeIcon
            icon={['fal', 'check']}
            size="lg"
            color={theme?.old.palette?.chromaticPalette?.green}
          />
        ),
        error: (
          <FontAwesomeIcon
            icon={['fal', 'xmark']}
            size="lg"
            color={theme?.old.palette?.chromaticPalette?.red}
          />
        ),
        new: (
          <span style={{ color: theme.old.palette.chromaticPalette.blue }}>
            NEW
          </span>
        ),
      };
      return statuses[status] || null;
    },
    [theme]
  );

  const columns: Column<OfficeHoliday>[] = useMemo(
    () => [
      {
        id: 'date',
        alignSelf: true,
        width: 33,
        title: t('absences:officeHolidays.table.columnTitle.date'),
        accessor: 'date',
        Cell: (cellProps: any) => moment(cellProps.value).format('DD.MM.YYYY'),
        sortingFn: (a: OfficeHoliday, b: OfficeHoliday) =>
          moment(a.date).diff(moment(b.date)) > 0 ? 1 : -1,
      },
      {
        id: 'name',
        alignSelf: true,
        width: 33,
        title: t('absences:officeHolidays.table.columnTitle.name'),
        accessor: 'name',
        Cell: (cellProps: any) => cellProps.value,
        sortingFn: (a: OfficeHoliday, b: OfficeHoliday) =>
          a.name.localeCompare(b.name),
      },
      {
        id: 'isHalfDay',
        alignSelf: true,
        width: 14,
        title: t('absences:officeHolidays.table.columnTitle.isHalfDay'),
        accessor: 'isHalfDay',
        Cell: (cellProps: any) =>
          cellProps.value ? (
            <div className={classes.center}>
              <FontAwesomeIcon icon={['fal', 'check']} />
            </div>
          ) : (
            <></>
          ),
      },
      {
        id: 'type',
        alignSelf: true,
        width: 10,
        title: t('absences:officeHolidays.table.columnTitle.type'),
        accessor: 'holidayType',
        Cell: (cellProps: any) =>
          cellProps.value === 'Public' ? (
            <div
              className={classes.center}
              title={t('absences:officeHolidays.table.iconDescription.globe')}
            >
              <FontAwesomeIcon icon={['fal', 'globe']} />
            </div>
          ) : (
            <div
              className={classes.center}
              title={t(
                'absences:officeHolidays.table.iconDescription.briefcase'
              )}
            >
              <FontAwesomeIcon icon={['fal', 'briefcase']} />
            </div>
          ),
      },
      {
        id: 'options',
        alignSelf: true,
        width: 10,
        title: '',
        accessor: 'officeHolidayId',
        Cell: (cellProps: any) => (
          <Dropdown
            overlay={() =>
              menuProjectList(
                cellProps.originalData,
                cellProps.originalData?.holidayType === 'Office'
                  ? ['copy', 'copyToNextYear', 'edit', 'delete']
                  : ['copy'],
                onCopy,
                onEdit,
                onDelete,
                onCopyToNextYear
              )
            }
            trigger={['click']}
            placement="bottomRight"
            className={classes.center}
          >
            {getFeedbackIconForStatus(
              holidayActionFeedback.filter(
                (h) => h.id === cellProps.originalData?.officeHolidayId
              )[0]?.status
            ) ||
              (holidayActionLoading.includes(
                cellProps.originalData?.officeHolidayId
              ) ? (
                <PrioSpinner size="small" />
              ) : (
                <FontAwesomeIcon
                  className={classes.cursorPointer}
                  icon={['fal', 'ellipsis-v']}
                ></FontAwesomeIcon>
              ))}
          </Dropdown>
        ),
      },
    ],
    [
      holidayActionLoading,
      holidayActionFeedback,
      classes,
      menuProjectList,
      onCopy,
      onCopyToNextYear,
      onEdit,
      onDelete,
      t,
      getFeedbackIconForStatus,
    ]
  );

  return (
    <>
      <Form
        style={{ gap: theme.spacing.defaultPadding }}
        layout="vertical"
        className={classes.content}
      >
        <Row gutter={theme.spacing.unit(rowGutter)}>
          <Col span={8}>
            <Form.Item label={t('common:filter.label.period')}>
              <Select
                className={classes.fullWidth}
                placeholder={t('common:filter.placeholder.period')}
                value={year}
                defaultValue={year}
                onChange={handleYearPicker}
                onSearch={(value) => {
                  if (value.length === 4 && !isNaN(Number(value))) {
                    handleYearPicker(Number(value));
                  }
                }}
                showSearch
              >
                {getYearOptions()}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t('absences:form.officeHolidays.filter.type')}>
              <Checkbox
                checked={onlyShowOfficeHolidays}
                onClick={() =>
                  setOnlyShowOfficeHolidays(!onlyShowOfficeHolidays)
                }
              >
                {t('absences:form.officeHolidays.onlyOfficeHolidays')}
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ flex: '1' }} gutter={theme.spacing.unit(rowGutter)}>
          <Col className={classes.fullWidth}>
            <VirtualTable<OfficeHoliday>
              resizable="relative"
              id="holidaytable"
              columns={columns}
              data={filteredHolidaysList}
              className={classes.fullWidth}
              noItemsScreen={
                <div className={classNames(classes.center, classes.flexCol)}>
                  {isHolidaysLoading ? (
                    <PrioSpinner />
                  ) : (
                    <svg
                      className="ant-empty-img-simple"
                      width="64"
                      height="41"
                      viewBox="0 0 64 41"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        transform="translate(0 1)"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <ellipse
                          className="ant-empty-img-simple-ellipse"
                          cx="32"
                          cy="33"
                          rx="32"
                          ry="7"
                        ></ellipse>
                        <g
                          className="ant-empty-img-simple-g"
                          fill-rule="nonzero"
                        >
                          <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                          <path
                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                            className="ant-empty-img-simple-path"
                          ></path>
                        </g>
                      </g>
                    </svg>
                  )}
                </div>
              }
            />
          </Col>
        </Row>
      </Form>
      <Modal
        title={
          (holidayInModal?.officeHolidayId &&
            (holidayInModal?.officeHolidayId === 'new'
              ? null
              : holidayInModal?.officeHolidayId === 'copy'
              ? t('absences:officeHolidays.modal.titles.copy')
              : t('absences:officeHolidays.modal.titles.edit'))) ||
          t('absences:officeHolidays.modal.titles.new')
        }
        visible={holidayModalVisible}
        onOk={() => handleHolidayModalOk(holidayInModal)}
        onCancel={() => setHolidayModalVisible(false)}
        cancelText={t('mail:modals.assignMessage.cancelText')}
        okText={
          (holidayInModal?.officeHolidayId &&
            (holidayInModal?.officeHolidayId === 'new'
              ? null
              : holidayInModal?.officeHolidayId === 'copy'
              ? t('absences:officeHolidays.modal.titles.copy')
              : t('absences:officeHolidays.modal.titles.edit'))) ||
          t('absences:officeHolidays.modal.titles.new')
        }
        footer={[
          <Button
            key="cancel"
            type="link"
            onClick={() => setHolidayModalVisible(false)}
            style={{ marginRight: theme.old.spacing.unit(1) }}
          >
            {t('mail:modals.assignMessage.cancelText')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={holidayActionLoading.includes(
              holidayInModal?.officeHolidayId
            )}
            onClick={() => handleHolidayModalOk(holidayInModal)}
          >
            {(holidayInModal?.officeHolidayId &&
              (holidayInModal?.officeHolidayId === 'new'
                ? null
                : holidayInModal?.officeHolidayId === 'copy'
                ? t('absences:officeHolidays.modal.titles.copy')
                : t('absences:officeHolidays.modal.titles.edit'))) ||
              t('absences:officeHolidays.modal.titles.new')}
          </Button>,
        ]}
      >
        <Form
          className={classNames(classes.showOverflow, classes.content)}
          style={{ padding: '0px' }}
          layout="vertical"
        >
          <Row>
            <Form.Item
              label={t('absences:officeHolidays.table.columnTitle.date')}
            >
              <CustomSingleDatePicker
                disabled={
                  holidayInModal?.officeHolidayId &&
                  ['new', 'copy'].includes(holidayInModal?.officeHolidayId) ===
                    false
                }
                id="office_holiday_datepicker"
                value={moment(holidayInModal?.date)}
                onChange={(changeDate) =>
                  changeDate?.toISOString()
                    ? setHolidayInModal({
                        ...holidayInModal,
                        date: changeDate?.toISOString(),
                      })
                    : {}
                }
                anchorDirection={'ANCHOR_RIGHT'}
                small={true}
                regular={false}
                twoMonths={false}
                withFullScreenPortal={false}
                daySize={30}
                hideKeyboardShortcutsPanel={true}
                showDefaultInputIcon={true}
                inputIconPosition={'after'}
              />
              {holidayInModal?.officeHolidayId &&
                ['new', 'copy'].includes(holidayInModal?.officeHolidayId) &&
                holidayWithDateAlreadyExists(holidayInModal?.date) && (
                  <Text className={classes.smallErrorText} type="danger">
                    {t(
                      'absences:officeHolidays.modal.errorText.datePicker.dateAlreadyExists'
                    )}
                  </Text>
                )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item
              validateStatus={
                holidayInModal?.name?.length > 0 ? 'success' : 'error'
              }
              label={t('absences:officeHolidays.table.columnTitle.name') + ' *'}
            >
              <Input
                value={holidayInModal?.name}
                placeholder="Holiday name"
                onChange={(e) =>
                  setHolidayInModal({
                    ...holidayInModal,
                    name: e?.target?.value || '',
                  })
                }
              />
            </Form.Item>
          </Row>

          <Row>
            <Form.Item
              label={t('absences:officeHolidays.table.columnTitle.isHalfDay')}
            >
              <Checkbox
                checked={holidayInModal?.isHalfDay}
                onChange={(e) =>
                  setHolidayInModal({
                    ...holidayInModal,
                    isHalfDay: e?.target?.checked,
                  })
                }
              >
                {t('absences:officeHolidays.table.columnTitle.isHalfDay')}
              </Checkbox>
            </Form.Item>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={t('absences:officeHolidays.table.dropdown.actions.delete')}
        visible={deleteHolidayModalVisible}
        onOk={() => handleDeleteHolidayModalOk(deleteHolidayIdInModal)}
        onCancel={() => setDeleteHolidayModalVisible(false)}
        cancelText={t('mail:modals.assignMessage.cancelText')}
        okText={t('absences:officeHolidays.table.dropdown.actions.delete')}
        footer={[
          <Button
            type="default"
            key="cancel"
            onClick={() => setDeleteHolidayModalVisible(false)}
            style={{ marginRight: theme.old.spacing.unit(1) }}
          >
            {t('mail:modals.assignMessage.cancelText')}
          </Button>,
          <Button
            key="ok"
            type="primary"
            loading={holidayActionLoading.includes(deleteHolidayIdInModal)}
            onClick={() => handleDeleteHolidayModalOk(deleteHolidayIdInModal)}
          >
            {t('absences:officeHolidays.table.dropdown.actions.delete')}
          </Button>,
        ]}
      >
        {t('absences:officeHolidays.deleteModal.areYouSure')}
      </Modal>
    </>
  );
};

export default HRHolidayPage;
