import { useSelector } from 'react-redux';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { CompaniesByIdState } from '../../companies/reducers/companies';
import XLSX from 'xlsx';
import i18n from '../../../i18n';
import { Company } from '../../../models/Company';
import { Contact } from '../../../models/Contact';

export const exportContactsToExcelFile: (
  contacts: Contact[],
  companiesById: CompaniesByIdState
) => void = (contacts, companiesById) => {
  const contactsToExport = contacts.map((contact) => {
    const office = companiesById[contact?.companyId]?.offices.find(
      (_office) => _office.officeId === contact.officeId
    );

    return {
      [i18n.t(`contacts:form.labels.firstName`)]: contact.firstName,
      [i18n.t(`contacts:form.labels.lastName`)]: contact.lastName,
      [i18n.t(`contacts:form.labels.title`)]: contact.title,
      [i18n.t(`contacts:form.labels.gender`)]:
        contact.gender === 'male'
          ? i18n.t(`contacts:form.gender.male`)
          : contact.gender === 'female'
          ? i18n.t(`contacts:form.gender.female`)
          : i18n.t(`contacts:form.gender.diverse`),
      [i18n.t(`contacts:form.labels.eMail`)]: contact.eMail,
      [i18n.t(`contacts:form.labels.eMail2`)]: contact.eMail2,
      [i18n.t(`contacts:form.labels.phone`)]: contact.phone,
      [i18n.t(`contacts:form.labels.phone2`)]: contact.phone2,
      [i18n.t(`contacts:form.labels.cellphone`)]: contact.cellphone,
      [i18n.t(`contacts:form.labels.cellphone2`)]: contact.cellphone2,
      [i18n.t(`contacts:form.labels.fax`)]: contact.fax,
      [i18n.t(`contacts:form.labels.notes`)]: contact.notes,
      [i18n.t(`contacts:form.labels.isArchived`)]: contact.isArchived
        ? i18n.t(`contacts:form.labels.true`)
        : i18n.t(`contacts:form.labels.false`),
      [i18n.t(`contacts:form.labels.contactType`)]:
        contact.contactType === 'InternalContact'
          ? i18n.t(`contacts:form.contactType.internalContact`)
          : i18n.t(`contacts:form.contactType.externalContact`),
      [i18n.t(`contacts:form.labels.companyName`)]:
        companiesById[contact.companyId]?.fullName,
      [i18n.t(`contacts:form.labels.street`)]: office?.street,
      [i18n.t(`contacts:form.labels.zip`)]: office?.zip,
      [i18n.t(`contacts:form.labels.city`)]: office?.city,
      [i18n.t(`contacts:form.labels.federalStateCode`)]:
        office?.federalStateCode,
      [i18n.t(`contacts:form.labels.countryCode`)]: office?.countryCode,
    };
  });

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(contactsToExport);

  XLSX.utils.book_append_sheet(
    wb,
    ws,
    i18n.t('contacts:exportList.contactListTitle')
  );
  XLSX.writeFile(wb, `${i18n.t('contacts:exportList.contactListTitle')}.xlsx`);
};

export const exportCompaniesToExcelFile: (companies: Company[]) => void = (
  companies
) => {
  const companiesToExport = companies.map((company) => {
    const mainOffice = company?.offices?.find((o) => o.isMainOffice);
    const firstBankAccount =
      company.bankAccounts.length > 0 ? company.bankAccounts[0] : null;

    return {
      [i18n.t(`companies:form.labels.shortName`)]: company.shortName,
      [i18n.t(`companies:form.labels.fullName`)]: company.fullName,
      [i18n.t(`companies:form.labels.fullName2`)]: company.fullName2,
      [i18n.t(`companies:form.labels.website`)]: company.website,
      [i18n.t(`companies:form.labels.notes`)]: company.notes,
      [i18n.t(`companies:companyType.title`)]:
        company.companyType === 'InternalCompany'
          ? i18n.t(`companies:companyType.internalCompany`)
          : company.companyType === 'ExternalCompany'
          ? i18n.t(`companies:companyType.externalCompany`)
          : i18n.t(`companies:companyType.contactCompany`),
      [i18n.t(`companies:form.labels.eMail`)]: mainOffice?.eMail,
      [i18n.t(`companies:form.labels.phone`)]: mainOffice?.phone,
      [i18n.t(`companies:form.labels.street`)]: mainOffice?.street,
      [i18n.t(`companies:form.labels.zip`)]: mainOffice?.zip,
      [i18n.t(`companies:form.labels.city`)]: mainOffice?.city,
      [i18n.t(`companies:form.labels.federalStateCode`)]:
        mainOffice?.federalStateCode,
      [i18n.t(`companies:form.labels.countryCode`)]: mainOffice?.countryCode,
      [i18n.t(`companies:internalCompanyForm.labels.creditInstitutionName`)]:
        firstBankAccount?.creditInstitutionName,
      [i18n.t(`companies:internalCompanyForm.labels.bic`)]:
        firstBankAccount?.bic,
      [i18n.t(`companies:internalCompanyForm.labels.iban`)]:
        firstBankAccount?.iban,
    };
  });

  var wb = XLSX.utils.book_new();
  var ws = XLSX.utils.json_to_sheet(companiesToExport);

  XLSX.utils.book_append_sheet(
    wb,
    ws,
    i18n.t('contacts:exportList.companyListTitle')
  );
  XLSX.writeFile(wb, `${i18n.t('contacts:exportList.companyListTitle')}.xlsx`);
};

export function useExportContactsToExcelFile(): (contacts: Contact[]) => void {
  const companiesById = useSelector(getCompaniesByIdState);

  return (contacts: Contact[]) =>
    exportContactsToExcelFile(contacts, companiesById);
}
export function useExportCompaniesToExcelFile(): (
  companies: Company[]
) => void {
  return (companies: Company[]) => exportCompaniesToExcelFile(companies);
}
