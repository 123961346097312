import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Route,
  Routes as ReactRouterDomRoutes,
  useParams,
} from 'react-router-dom';
import { Breadcrumb, Tooltip, Menu, BreadcrumbItemProps } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllInternalOffices,
  getAllProjects,
  getNotificationsByIdState,
  getNotificationsIds,
  getOffice,
  getOfficeMeAllOffices,
  getProject,
  getProjectByIdState,
  getUserMe,
  RootReducerState,
} from '../apps/main/rootReducer';
import { Office } from '../models/Office';
import { Project } from '../models/Project';
import Flex from './Flex';
import { MainMenu } from '../modules/main/MainMenu';
import { openContactsDrawer } from '../modules/contacts/actions/drawer';
import { openNotificationsDrawer } from '../modules/notifications/actions/drawer';
import { openZendeskDrawer } from '../modules/zendesk/actions/drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { createSelector } from 'reselect';
import { User } from '../models/User';
import { NotificationId, OfficeRole } from '../models/Types';
import { distinct, isTemporaryId } from '../util';
import useAccessRights from '../modules/users/hooks/useAccessRights';
import { fetchInternalOffices } from '../modules/companies/actions';
import AccountInformationPanel from './AccountInformationPanel';
import i18n from '../i18n';
import useTabBarTitle from '../hooks/useTabBarTitle';
import { PrioTheme } from '../theme/types';
import { makePrioStyles } from '../theme/utils';
import { useTheme } from 'react-jss';
import { NotificationsByIdState } from '../modules/notifications/reducers/notifications';
import { openTimeAndLeaveManagementDrawer } from '../modules/timeAndLeaveManagement/actions';
import { sortProjects } from '../modules/projects/utils';
import * as Sentry from '@sentry/react';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    minHeight:
      theme.old.components.globalNavigationBar.height ??
      theme.old.spacing.defaultPadding * 2,
    height:
      theme.old.components.globalNavigationBar.height ??
      theme.old.spacing.defaultPadding * 2,
    paddingLeft: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.base,
  },
  breadCrumb: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: theme.old.typography.colors.invertedMuted,
    '& > span:nth-child(2)': {
      display: 'flex',
      overflow: 'hidden',
      '& .ant-dropdown-trigger': {
        flex: 1,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        '& .ant-breadcrumb-link': {
          flex: 1,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },
    },
    '& > span:last-child': {
      color: theme.old.typography.colors.inverted,
    },
    '& .ant-breadcrumb-separator': {
      color: theme.old.typography.colors.invertedMuted,
    },
    '& .anticon': {
      color: theme.old.typography.colors.invertedMuted,
    },
  },
  dot: {
    position: 'absolute',
    height: 15,
    borderRadius: 15,
    backgroundColor: theme.old.palette.chromaticPalette.red,
    color: theme.old.typography.colors.inverted,
    fontSize: '11px',
    textAlign: 'center',
    zIndex: 10,
    margin: '0 0 0 20px',
    '& > p': {
      marginTop: -1,
    },
    padding: '0 4px',
  },
  notificationActive: {
    color: theme.old.palette.chromaticPalette.yellow + ' !important',
    animation: 'shake 2s 3 ease-in-out',
  },
  dropdownList: {
    maxHeight: '25vh',
    maxWidth: '25vw',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& .ant-dropdown-menu-title-content': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  button: {
    backgroundColor: theme.old.palette.backgroundPalette.base,
    '&.prio-btn:hover, .prio-btn:active, .prio-btn:focus': {
      backgroundColor: theme.old.palette.backgroundPalette.base,
    },
    '&.prio-btn > .svg-inline--fa': {
      color: theme.old.typography.colors.inverted,
    },
  },
  backgroundColor: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
}));

declare type ModuleType =
  | 'projects'
  | 'contacts'
  | 'hr'
  | 'controlling'
  | 'settings'
  | 'profile';

interface SecondMenuItem {
  id: string;
  name: string;
  link?: string;
  children?: SecondMenuItem[];
  isSubproject?: boolean;
}

const secondMenuItemsSelector = (module: ModuleType) =>
  createSelector<
    [
      (state: RootReducerState) => Office[],
      (state: RootReducerState) => Office[],
      (state: RootReducerState) => Project[],
      (state: RootReducerState) => User,
    ],
    SecondMenuItem[]
  >(
    getOfficeMeAllOffices,
    (state) => getAllInternalOffices(state),
    getAllProjects,
    getUserMe,
    (myOffices, allOffices, favProjects, userMe) => {
      const globalRoles = userMe?.prioData?.globalRoles ?? [];
      const officeRoles =
        module === 'hr' || module === 'controlling'
          ? Object.values(userMe?.prioData?.officeRoles ?? {})?.reduce<
              OfficeRole[]
            >(
              (aggregation, current) => distinct([...aggregation, ...current]),
              []
            ) ?? []
          : [];
      switch (module) {
        case 'projects': {
          return [
            {
              id: 'me',
              name: `${userMe?.givenName} ${userMe?.surname}`,
              children: [
                {
                  id: 'dashboard',
                  name: i18n.t('common:navigationBar.projects.me.dashboard'),
                  link: '/module/prio/projects/me/dashboard',
                },
                {
                  id: 'mail',
                  name: i18n.t('common:navigationBar.projects.me.mail'),
                  link: '/module/prio/projects/me/mail',
                },
                {
                  id: 'messageCenter',
                  name: i18n.t(
                    'common:navigationBar.projects.me.messageCenter'
                  ),
                  link: '/module/prio/projects/me/messageCenter',
                },
              ],
            },
            {
              id: 'projects',
              name: i18n.t('common:navigationBar.projects.title'),
              children: favProjects.map((project) => ({
                id: project.projectId,
                name: `${project.number} ${project.shortName ?? project.name}`,
                link: `/module/prio/projects/${project.projectId}/${
                  project.sharedMailboxUserId ? 'mail' : 'summary'
                }`,
              })),
            },
          ];
        }
        case 'hr': {
          if (globalRoles?.includes('globalHR')) {
            return allOffices.map((office) => ({
              id: office.officeId,
              name: office.name,
            }));
          } else if (officeRoles?.includes('officeHR')) {
            return myOffices
              ?.filter(
                (office) =>
                  userMe?.prioData?.officeRoles[office.officeId]?.includes(
                    'officeHR'
                  )
              )
              ?.map((office) => ({
                id: office.officeId,
                name: office.name,
              }));
          }
          return [];
        }
        case 'controlling': {
          if (
            globalRoles?.includes('globalAdmin') ||
            globalRoles?.includes('globalAssistance') ||
            globalRoles?.includes('globalController')
          ) {
            return allOffices.map((office) => ({
              id: office.officeId,
              name: office.name,
            }));
          } else if (
            officeRoles?.includes('officeController') ||
            officeRoles?.includes('officeAdmin')
          ) {
            return myOffices
              ?.filter(
                (office) =>
                  userMe.prioData.officeRoles[office.officeId]?.includes(
                    'officeController'
                  ) ||
                  userMe.prioData.officeRoles[office.officeId]?.includes(
                    'officeAdmin'
                  )
              )
              ?.map((office) => ({
                id: office.officeId,
                name: office.name,
              }));
          }
          return [];
        }
        default:
          return [];
      }
    }
  );

const showNotificationSelector = createSelector<
  [
    (state: RootReducerState) => NotificationsByIdState,
    (state: RootReducerState) => NotificationId[],
  ],
  number
>(
  getNotificationsByIdState,
  getNotificationsIds,
  (byId, ids) =>
    ids
      .map((id) => byId[id])
      .filter(
        (notification) =>
          (notification?.isArchived ?? false) === false &&
          (notification?.isRead ?? false) === false &&
          ((notification?.type ?? false) === 'absence' ||
            (notification?.type ?? false) === 'training')
      ).length
);

interface GlobalNavigationBarProps {
  module: ModuleType;
}

export const GlobalNavigationBar: React.FC<GlobalNavigationBarProps> = (
  props
) => {
  const { module } = props;
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();

  const showNotification = useSelector(showNotificationSelector);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const showContactsDrawer = () => {
    dispatch(openContactsDrawer({ view: 'list' }));
  };

  const showTimeAndLeaveManagement = () =>
    dispatch(openTimeAndLeaveManagementDrawer());

  const showNotificationsDrawer = () =>
    dispatch(openNotificationsDrawer({ view: 'list' }));

  const showZendeskDrawer = () => dispatch(openZendeskDrawer(true));
  //#endregion

  return (
    <Flex.Row
      className={classes.root}
      childrenGap={theme.old.spacing.defaultPadding}
      id="prio-global-navigation-bar"
    >
      <Flex.Item className={classes.breadCrumb}>
        <Routes>
          <Route
            path=":idOrSelectedList/*"
            element={<NavigationBreadcrumb module={module} />}
          />
          <Route
            path="office/:officeId/:selectedList/*"
            element={<NavigationBreadcrumb module={module} isOfficeContext />}
          />
        </Routes>
      </Flex.Item>

      <Flex.Row
        alignItems="center"
        justifyContent="flex-end"
        childrenGap={theme.old.spacing.unit(1)}
      >
        <Routes>
          <Route
            path="*"
            element={
              <>
                <Tooltip title={t('common:navigationBar.contacts')}>
                  <div>
                    <Button
                      id="prio-global-contacts-drawer-button"
                      onClick={showContactsDrawer}
                      className={classes.button}
                      iconProp={['fal', 'address-book']}
                    ></Button>
                  </div>
                </Tooltip>
                <Tooltip
                  title={t('common:navigationBar.timeAndLeaveManagement')}
                >
                  <div>
                    <Button
                      id="prio-global-timeAndLeaveManagement-drawer-button"
                      onClick={showTimeAndLeaveManagement}
                      className={classes.button}
                      iconProp={['fal', 'calendar-range']}
                    ></Button>
                  </div>
                </Tooltip>
                <Tooltip title={t('common:navigationBar.notifications')}>
                  {showNotification > 0 ? (
                    <div>
                      <div className={classes.dot}>
                        <p>
                          {showNotification > 99 ? '99+' : showNotification}
                        </p>
                      </div>
                      <div>
                        <Button
                          id="prio-global-notifications-drawer-button"
                          onClick={showNotificationsDrawer}
                          className={classes.button}
                        >
                          <FontAwesomeIcon
                            icon={['fas', 'bell']}
                            className={classes.notificationActive}
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Button
                        id="prio-global-notifications-drawer-button"
                        onClick={showNotificationsDrawer}
                        className={classes.button}
                        iconProp={['fal', 'bell']}
                      ></Button>
                    </div>
                  )}
                </Tooltip>
                <Tooltip title={t('common:navigationBar.zendesk')}>
                  <div>
                    <Button
                      id="prio-global-support-drawer-button"
                      onClick={showZendeskDrawer}
                      className={classes.button}
                      iconProp={['fal', 'question-circle']}
                    ></Button>
                  </div>
                </Tooltip>
                <AccountInformationPanel />
              </>
            }
          />
        </Routes>
      </Flex.Row>
    </Flex.Row>
  );
};

export default GlobalNavigationBar;

const moduleTranslationMap = {
  projects: 'projects:navigation',
  contacts: 'contacts:contactsNav.navigation',
  controlling: 'controlling:navigation',
  hr: 'hr:navigation',
  settings: 'settings:settingsNav.navigation',
  profile: 'profile:profileNavigation',
};

const renderSecondItemMenu = (
  item: SecondMenuItem,
  module: ModuleType,
  onItemClick: VoidFunction
) => {
  if (item.children) {
    if (item.children.length > 0) {
      return (
        <Menu.ItemGroup title={item.name} key={item.id}>
          {item.children.map((child) =>
            renderSecondItemMenu(child, module, onItemClick)
          )}
        </Menu.ItemGroup>
      );
    }
    return null;
  }
  return (
    <Menu.Item key={item.id}>
      <Link
        to={
          item.link ??
          `/module/prio/${module}/${module !== 'projects' ? 'office/' : ''}${
            item.id
          }/${
            module === 'projects'
              ? 'mail'
              : module === 'hr'
              ? 'absence'
              : 'invoices'
          }`
        }
        onClick={onItemClick}
      >
        <>
          {item.isSubproject && (
            <FontAwesomeIcon
              size="sm"
              icon={['fal', 'horizontal-rule']}
              style={{ marginRight: '8px' }}
            />
          )}
          {item.name}
        </>
      </Link>
    </Menu.Item>
  );
};

interface NavigationBreadcrumbProps {
  module: ModuleType;
  isOfficeContext?: boolean;
}

const NavigationBreadcrumb: React.FC<NavigationBreadcrumbProps> = (props) => {
  const { isOfficeContext, module } = props;

  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idOrSelectedList, officeId, selectedList, ...rest } = useParams();
  const {
    showGlobalInControllingModule,
    showGlobalInHrModule,
    showOfficesInControllingModule,
    showOfficesInHrModule,
  } = useAccessRights([
    'showGlobalInControllingModule',
    'showGlobalInHrModule',
    'showOfficesInControllingModule',
    'showOfficesInHrModule',
  ]);

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const secondMenuItems = useSelector(secondMenuItemsSelector(module));
  const projectsById = useSelector(getProjectByIdState);

  const secondMenuItemsSorted: SecondMenuItem[] = useMemo(() => {
    if (secondMenuItems && module === 'projects') {
      const _projects = secondMenuItems?.[1]?.children;
      const projects = _projects?.map((item) => {
        return projectsById?.[item.id];
      });

      const sortedProjects = sortProjects(projects ?? []).filter(
        ({ favorite }) => favorite
      );
      const children: SecondMenuItem[] = sortedProjects?.map((item) => {
        return {
          id: item?.projectId,
          name: `${item?.number} ${item?.shortName}`,
          link: `/module/prio/projects/${item?.projectId}/${
            !!item?.parentProject ? 'summary' : 'mail'
          }`,
          isSubproject: !!item?.parentProject,
        };
      });

      const _secondMenuItems = secondMenuItems ? [...secondMenuItems] : [];
      if (_secondMenuItems?.[1]?.children) {
        _secondMenuItems[1].children = children;
      }
      return _secondMenuItems;
    }
    return [];
  }, [secondMenuItems, projectsById, module]);

  const [secondItemDropDownOpen, setSecondItemDropDownOpen] =
    useState<boolean>(false);

  const [dropDownOpen, setDropDownOpen] = useState<boolean>(false);

  const isUuid = idOrSelectedList?.match(
    /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/
  );
  const isTemporary = isTemporaryId(idOrSelectedList);
  const office = useSelector<RootReducerState, Office>((state) =>
    getOffice(state, officeId)
  );
  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, isUuid || isTemporary ? idOrSelectedList : null)
  );
  const userMe = useSelector(getUserMe);

  const isSelectedListMe = idOrSelectedList === 'me';

  const secondItem: string = useMemo(() => {
    if (isOfficeContext && office) {
      return office.name;
    }
    if ((isUuid || isTemporary) && project) {
      return `${project.number} ${project.shortName ?? project.name}`;
    }
    if (!isUuid && idOrSelectedList) {
      if (module === 'projects' && idOrSelectedList === 'me') {
        return `${userMe?.givenName ?? ''} ${userMe?.surname ?? ''}`;
      }
      return t(`${moduleTranslationMap[module]}.${idOrSelectedList}`);
    }
    return null;
  }, [
    isOfficeContext,
    office,
    project,
    isUuid,
    isTemporary,
    idOrSelectedList,
    module,
    userMe,
    t,
  ]);

  const thirdItem: string = useMemo(() => {
    if (isUuid) {
      if (rest['*'] && rest['*'].split('/').length > 0) {
        return rest['*'].split('/')[0];
      }
      return '';
    }
    if (isSelectedListMe) {
      if (rest['*'] && rest['*'].split('/').length > 0) {
        return rest['*'].split('/')[0];
      }
      return '';
    }
    if (selectedList) {
      return selectedList;
    }
    return null;
  }, [isUuid, rest, selectedList, isSelectedListMe]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const closeDropDown = () => setDropDownOpen(false);
  //#endregion

  const secondItemProps: BreadcrumbItemProps = useMemo(() => {
    if (
      secondMenuItemsSorted.length > 0 &&
      (office || project || (module === 'projects' && isSelectedListMe))
    ) {
      const selectedKeys = [
        module === 'projects'
          ? isSelectedListMe
            ? 'me'
            : project?.projectId ?? ''
          : office?.officeId ?? '',
      ];
      const overlay = (
        <Menu selectedKeys={selectedKeys} className={classes.dropdownList}>
          {secondMenuItemsSorted.map((item) =>
            renderSecondItemMenu(item, module, () =>
              setSecondItemDropDownOpen(false)
            )
          )}
        </Menu>
      );
      return {
        dropdownProps: {
          visible: secondItemDropDownOpen,
          trigger: ['click'],
          onVisibleChange: setSecondItemDropDownOpen,
          placement: 'bottomLeft',
          overlay,
          overlayClassName: classes.backgroundColor,
        },
        overlay,
      };
    }
    return null;
  }, [
    project,
    secondMenuItemsSorted,
    setSecondItemDropDownOpen,
    secondItemDropDownOpen,
    office,
    module,
    isSelectedListMe,
    classes,
  ]);

  React.useEffect(() => {
    if (
      (module === 'hr' && showGlobalInHrModule) ||
      (module === 'controlling' && showGlobalInControllingModule)
    ) {
      dispatch(fetchInternalOffices());
    }
  }, [
    dispatch,
    module,
    showGlobalInHrModule,
    showGlobalInControllingModule,
    showOfficesInHrModule,
    showOfficesInControllingModule,
  ]);

  useTabBarTitle(module, secondItem, thirdItem);

  return (
    <Breadcrumb className={classes.breadCrumb}>
      <Breadcrumb.Item
        dropdownProps={{
          visible: dropDownOpen,
          trigger: ['click'],
          overlay: (
            <MainMenu closeDropDown={closeDropDown} selectedEntry={module} />
          ),
          onVisibleChange: setDropDownOpen,
          placement: 'bottomLeft',
        }}
        overlay={
          <MainMenu closeDropDown={closeDropDown} selectedEntry={module} />
        }
      >
        {t(`common:links.${module}`)}
      </Breadcrumb.Item>
      {secondItem && (
        <Breadcrumb.Item {...secondItemProps}>{secondItem}</Breadcrumb.Item>
      )}
      {thirdItem && (
        <Breadcrumb.Item>
          {t(`${moduleTranslationMap[module]}.${thirdItem}`)}
        </Breadcrumb.Item>
      )}
    </Breadcrumb>
  );
};
